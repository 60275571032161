export const attPointLayer = {
    id: 'att-signal-circles',
    type: 'circle',
    paint: {
      'circle-radius': 6,
      'circle-color': '#2BB0ED',
      'circle-opacity': .5
    }
};

export const verizonPointLayer = {
  id: 'verizon-signal-circles',
  type: 'circle',
  paint: {
    'circle-radius': 6,
    'circle-color': '#CF1124',
    'circle-opacity': .5
  }
};

export const tmobilePointLayer = {
  id: 'tmobile-signal-circles',
  type: 'circle',
  paint: {
    'circle-radius': 6,
    'circle-color': '#27AB83',
    'circle-opacity': .5
  }
};

export const highlightedPointLayer = {
  id: 'highlighted-point',
  type: 'circle',
  paint: {
    'circle-radius': 13,
    'circle-color': '#3F9142',
    'circle-blur': .2
  }
};

export const priorityPointLayer = {
  id: 'priority-point',
  type: 'circle',
  paint: {
    'circle-radius': 10,
    'circle-color': '#F7C948',
    'circle-blur': .5
  }
};