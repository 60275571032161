import React, {useState} from 'react';
import DateModeOption from './DateModeOption';



function ControlPanel(props) {
  const {
    dateMode,
    dateModeCheckbox,
    handleDateModeEnter,
    filters,
    setFilters
  } = props;

  const handleStrengthToggle = (signalStrength)=>{
    let newSignalStrengths; 
    if(filters.strengths.includes(signalStrength)){
      newSignalStrengths = filters.strengths.filter(x => x != signalStrength);
    }else{
      newSignalStrengths = filters.strengths;
      newSignalStrengths.push(signalStrength);
    }
  
    let newFilters = filters;
    newFilters.strengths = newSignalStrengths;
    setFilters({...newFilters});
  
  }

const handleSignalToggle = (signalName)=>{
  let newSignalTypes; 
  if(filters.signalTypes.includes(signalName)){
    newSignalTypes = filters.signalTypes.filter(x => x != signalName);
  }else{
    newSignalTypes = filters.signalTypes;
    newSignalTypes.push(signalName);
  }

  let newFilters = filters;
  newFilters.signalTypes = newSignalTypes;
  setFilters({...newFilters});
}

const handleCarrierToggle = (carrierName)=>{
  let newCarriers; 
  if(filters.carriers.includes(carrierName)){
    newCarriers = filters.carriers.filter(x => x != carrierName);
  }else{
    newCarriers = filters.carriers;
    newCarriers.push(carrierName);
  }

  let newFilters = filters;
  newFilters.carriers = newCarriers;
  setFilters({...newFilters});
}

  return (
    <div className='control-panel col'>
      <div className='option-area'>
          <h3 className='font-light light-color'>
              Signal Types
          </h3>

          <div className='filters'>
              <button 
                  onClick={()=>{handleSignalToggle('NrSignal')}}
                  className={`filter-box ${filters.signalTypes.includes('NrSignal') ? 'selected': ''}`}
              >
                  <h4 className={`light-color font-bold`}>
                      5G
                  </h4>
              </button>
              <button 
                  onClick={()=>{handleSignalToggle('LteSignal')}}
                  className={`filter-box ${filters.signalTypes.includes('LteSignal') ? 'selected': ''}`}
              >
                  <h4 className={`light-color font-bold`}>
                      4G
                  </h4>
              </button>
              <button 
                  onClick={()=>{handleSignalToggle('WcdmaSignal')}}
                  className={`filter-box ${filters.signalTypes.includes('WcdmaSignal') ? 'selected': ''}`}
              >
                  <h4 className={`light-color font-bold`}>
                      3G
                  </h4>
              </button>
              <button 
                  onClick={()=>{handleSignalToggle('GsmSignal')}}
                  className={`filter-box ${filters.signalTypes.includes('GsmSignal') ? 'selected': ''}`}
              >
                  <h4 className={`light-color font-bold`}>
                      2G
                  </h4>
              </button>
          </div>
      </div>
      <DateModeOption 
        startDate={filters.startDate} 
        endDate={filters.endDate} 
        dateModeCheckbox={dateModeCheckbox}
        dateMode={dateMode}
        handleDateModeEnter={handleDateModeEnter}
      />
      <div className='option-area'>
        <h3 className='font-light light-color'>
          Signal Strengths
        </h3>
        <div className='filters'>
            <button 
                onClick={()=>{handleStrengthToggle('poor')}}
                className={`filter-box sm ${filters.strengths.includes('poor') ? 'selected': ''}`}
            >
                <h6 className={`light-color font-bold`}>
                    Poor
                </h6>
            </button>
            <button 
                onClick={()=>{handleStrengthToggle('fair')}}
                className={`filter-box sm ${filters.strengths.includes('fair') ? 'selected': ''}`}
            >
                <h6 className={`light-color font-bold`}>
                    Fair
                </h6>
            </button>
            <button 
                onClick={()=>{handleStrengthToggle('good')}}
                className={`filter-box sm ${filters.strengths.includes('good') ? 'selected': ''}`}
            >
                <h6 className={`light-color font-bold`}>
                    Good
                </h6>
            </button>
            <button 
                onClick={()=>{handleStrengthToggle('best')}}
                className={`filter-box sm ${filters.strengths.includes('best') ? 'selected': ''}`}
            >
                <h6 className={`light-color font-bold`}>
                    Best
                </h6>
            </button>
        </div>
      </div>
      <div className='option-area'>
        <h3 className='font-light light-color'>
          Carriers
        </h3>
        <div className='filters'>
          <button 
            onClick={()=>{handleCarrierToggle('verizon')}}
            className={`filter-box ${filters.carriers.includes('verizon') ? 'selected': ''}`}
          >
            <h4 className={`light-color font-bold`}>
                Verizon
            </h4>
          </button>
        </div>
        <div className='filters'>
          <button 
            onClick={()=>{handleCarrierToggle('t-mobile')}}
            className={`filter-box ${filters.carriers.includes('t-mobile') ? 'selected': ''}`}
          >
            <h4 className={`light-color font-bold`}>
                T-Mobile
            </h4>
          </button>
        </div>
        <div className='filters'>
          <button 
            onClick={()=>{handleCarrierToggle('at-t')}}
            className={`filter-box ${filters.carriers.includes('at-t') ? 'selected': ''}`}
          >
            <h4 className={`light-color font-bold`}>
                AT&T
            </h4>
          </button>
        </div>
      </div>
    </div>
  );
}

export default ControlPanel;
