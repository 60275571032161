/*
310	410	us	United States of America	1	AT&T Wireless Inc.
310	380	us	United States of America	1	AT&T Wireless Inc.
310	170	us	United States of America	1	AT&T Wireless Inc.
310	150	us	United States of America	1	AT&T Wireless Inc.
310	680	us	United States of America	1	AT&T Wireless Inc.
310	070	us	United States of America	1	AT&T Wireless Inc.
310	560	us	United States of America	1	AT&T Wireless Inc.
310	980	us	United States of America	1	AT&T Wireless Inc.  
*/

/*
311	274	us	United States of America	1	Verizon Wireless
311	390	us	United States of America	1	Verizon Wireless
310	010	us	United States of America	1	Verizon Wireless
311	279	us	United States of America	1	Verizon Wireless
310	910	us	United States of America	1	Verizon Wireless
311	284	us	United States of America	1	Verizon Wireless
311	484	us	United States of America	1	Verizon Wireless
311	273	us	United States of America	1	Verizon Wireless
311	289	us	United States of America	1	Verizon Wireless
311	489	us	United States of America	1	Verizon Wireless
310	004	us	United States of America	1	Verizon Wireless
311	278	us	United States of America	1	Verizon Wireless
310	890	us	United States of America	1	Verizon Wireless
311	283	us	United States of America	1	Verizon Wireless
311	483	us	United States of America	1	Verizon Wireless
311	272	us	United States of America	1	Verizon Wireless
311	288	us	United States of America	1	Verizon Wireless
311	488	us	United States of America	1	Verizon Wireless
311	277	us	United States of America	1	Verizon Wireless
310	590	us	United States of America	1	Verizon Wireless
311	282	us	United States of America	1	Verizon Wireless
311	482	us	United States of America	1	Verizon Wireless
311	271	us	United States of America	1	Verizon Wireless
311	287	us	United States of America	1	Verizon Wireless
311	487	us	United States of America	1	Verizon Wireless
311	276	us	United States of America	1	Verizon Wireless
311	481	us	United States of America	1	Verizon Wireless
310	013	us	United States of America	1	Verizon Wireless
311	281	us	United States of America	1	Verizon Wireless
311	270	us	United States of America	1	Verizon Wireless
311	286	us	United States of America	1	Verizon Wireless
311	486	us	United States of America	1	Verizon Wireless
311	275	us	United States of America	1	Verizon Wireless
311	480	us	United States of America	1	Verizon Wireless
310	012	us	United States of America	1	Verizon Wireless
311	280	us	United States of America	1	Verizon Wireless
311	110	us	United States of America	1	Verizon Wireless
311	285	us	United States of America	1	Verizon Wireless
311	485	us	United States of America	1	Verizon Wireless 
*/

/*
312	530	us	United States of America	1	Sprint Spectrum
310	120	us	United States of America	1	Sprint Spectrum
316	010	us	United States of America	1	Sprint Spectrum
312	190	us	United States of America	1	Sprint Spectrum
311	880	us	United States of America	1	Sprint Spectrum
311	870	us	United States of America	1	Sprint Spectrum
311	490	us	United States of America	1	Sprint Spectrum
310	660	us	United States of America	1	T-Mobile
310	230	us	United States of America	1	T-Mobile
310	31	us	United States of America	1	T-Mobile
310	220	us	United States of America	1	T-Mobile
310	270	us	United States of America	1	T-Mobile
310	210	us	United States of America	1	T-Mobile
310	260	us	United States of America	1	T-Mobile
310	200	us	United States of America	1	T-Mobile
310	250	us	United States of America	1	T-Mobile
310	160	us	United States of America	1	T-Mobile
310	240	us	United States of America	1	T-Mobile
310	300	us	United States of America	1	T-Mobile
310	280	us	United States of America	1	T-Mobile
310	330	us	United States of America	1	T-Mobile
310	800	us	United States of America	1	T-Mobile
310	310	us	United States of America	1	T-Mobile 
*/


const T_MOBILE_CODES = {
    310: [120, 660, 230, 31, 220, 270, 210, 260, 200, 250, 160, 240, 300, 280, 330, 800, 310],
    311: [880, 870, 490],
    312: [530, 190],
    316: ['010']
}
const ATandT_CODES = { 310: [410, 380, 170, 150, 680, '070', 560, 980] };
const VERIZON_CODES = {
    310: ['012', '013', 590, 890, '004', 910, '010'],
    311: [485, 285, 110, 280, 480, 275, 486, 286,
        270, 281, 481, 276, 487, 287, 271, 482, 282, 277, 488, 288, 272, 483, 283, 278, 489, 289, 273, 484, 284, 279, 390, 274
    ]
};

const SIGNAL_TYPES = ['GsmSignal', 'WcdmaSignal', 'LteSignal', 'NrSignal'];
const CARRIERS = ['T_Mobile', 'AT_and_T', 'Verizon'];

const UNAVAILABLE = 2147483647;
const SIGNAL_STRENGTH_GREAT = 4;
const SIGNAL_STRENGTH_GOOD = 3;
const SIGNAL_STRENGTH_MODERATE = 2;
const SIGNAL_STRENGTH_POOR = 1;
const SIGNAL_STRENGTH_NONE_OR_UNKNOWN = 0;
const SIGNAL_LEVEL_STR = [
    "Average Signal Strength: NONE OR UNKNOWN",
    "Average Signal Strength: POOR",
    "Average Signal Strength: MODERATE",
    "Average Signal Strength: GOOD",
    "Average Signal Strength: GREAT"
];
const SIGNAL_LEVEL_SCORE = {

    "best": (level) => level == SIGNAL_STRENGTH_GREAT,
    "good": (level) => level == SIGNAL_STRENGTH_GOOD,
    "fair": (level) => level == SIGNAL_STRENGTH_MODERATE,
    "poor": (level) => level == SIGNAL_STRENGTH_POOR,
    "none_or_unknown": (level) => level == SIGNAL_STRENGTH_NONE_OR_UNKNOWN

}
const RSRQ_BEST = -10;
const RSRQ_GOOD = -13;
const RSRQ_FAIR = -17;
const RSRQ_POOR = -20;

const RSRP_BEST = -62.4;
const RSRP_GOOD = -81.8;
const RSRP_FAIR = -101.2;
const RSRP_POOR = -120.6;

const SS_RSRP_BEST = -56;
const SS_RSRP_GOOD = -81;
const SS_RSRP_FAIR = -106;
const SS_RSRP_POOR = -131;

const SS_RSRQ_BEST = 7.4;
const SS_RSRQ_GOOD = -5.2;
const SS_RSRQ_FAIR = -17.8;
const SS_RSRQ_POOR = -30.4;


const LTE_PERFORMANCE_SCORE = {
    "rsrq": {
        "best": (rsrq) => rsrq > RSRQ_BEST,
        "good": (rsrq) => (rsrq <= RSRQ_BEST && rsrq > RSRQ_GOOD),
        "fair": (rsrq) => (rsrq <= RSRQ_GOOD && rsrq > RSRQ_FAIR),
        "poor": (rsrq) => (rsrq <= RSRQ_FAIR && rsrq > RSRQ_POOR),
        "none_or_unknown": (rsrq) => (rsrq <= RSRQ_POOR)
    },
    "rsrp": {
        "best": (rsrp) => rsrp >= RSRP_BEST,
        "good": (rsrp) => (rsrp < RSRP_BEST && rsrp >= RSRP_GOOD),
        "fair": (rsrp) => (rsrp < RSRP_GOOD && rsrp >= RSRP_FAIR),
        "poor": (rsrp) => (rsrp < RSRP_FAIR && rsrp > RSRP_POOR),
        "none_or_unknown": (rsrp) => (rsrp <= RSRP_POOR)
    },
    "level": SIGNAL_LEVEL_SCORE

};

const WCDMA_PERFORMANCE_SCORE = {
    // "dbm" attribute refers to RSCP (Received Signal Code Power) for cdma.
    "dbm": {
        "best": (dbm) => dbm >= -60,
        "good": (dbm) => (dbm < -60 && dbm >= -75),
        "fair": (dbm) => (dbm < -75 && dbm >= -85),
        "poor": (dbm) => (dbm < -85 && dbm >= -95),
        "none_or_unknown": (dbm) => (dbm < -95 && dbm >= -124)
    },
    "level": SIGNAL_LEVEL_SCORE
};

const NR_PERFORMANCE_SCORE = {

    "ss_rsrp": {
        "best": (rsrp) => rsrp >= SS_RSRP_BEST,
        "good": (rsrp) => (rsrp < SS_RSRP_BEST && rsrp >= SS_RSRP_GOOD),
        "fair": (rsrp) => (rsrp < SS_RSRP_GOOD && rsrp >= SS_RSRP_FAIR),
        "poor": (rsrp) => (rsrp < SS_RSRP_FAIR && rsrp > SS_RSRP_POOR),
        "none_or_unknown": (rsrp) => (rsrp <= SS_RSRP_POOR)
        // "best": (ss_rsrp) => ss_rsrp >= -85,
        // "good": (ss_rsrp) => (ss_rsrp < -85 && ss_rsrp >= -95),
        // "fair": (ss_rsrp) => (ss_rsrp < -95 && ss_rsrp >= -105),
        // "weak": (ss_rsrp) => (ss_rsrp < -105 && ss_rsrp >= -115),
        // "very_weak": (ss_rsrp) => (ss_rsrp < -115 && ss_rsrp >= -120),
        // "none_or_unknown": (ss_rsrp) => (ss_rsrp < -120)
    },
    "ss_rsrq": {
        "best": (ss_rsrq) => ss_rsrq > SS_RSRQ_BEST,
        "good": (ss_rsrq) => (ss_rsrq <= SS_RSRQ_BEST && ss_rsrq > SS_RSRQ_GOOD),
        "fair": (rsrq) => (rsrq <= SS_RSRQ_GOOD && rsrq > SS_RSRQ_FAIR),
        "poor": (rsrq) => (rsrq <= SS_RSRQ_FAIR && rsrq > SS_RSRQ_POOR),
        "none_or_unknown": (rsrq) => (rsrq <= SS_RSRQ_POOR)
    },
    "level": SIGNAL_LEVEL_SCORE
    // "ss_rsrq": -17,
    // "ss_sinr": -5,

};

function compute_average(arr) {
    var total = 0;
    for (var i = 0; i < arr.length; i++) {
        total += arr[i];

    }
    var avg = total / arr.length;
    return avg;
}

function all_great_signals(data) {
    return data.filter(d => d.signalable.level == SIGNAL_STRENGTH_GREAT);
}

function analyze_signal_performance(signal_data, signal_group_dict) {

    /* signal_group_dict = {'rsrq': 
                                {"great": great_score_func,
                                "good": good_score_func,
                                .
                                .
                                }
                            }
    Returns:
            The signal data performance based groups

    */
    var signal_groups = {};

    for (const [signalable_key, score_func_dict] of Object.entries(signal_group_dict)) {
        signal_groups[signalable_key] = {};
        for (const [score_key, score_function] of Object.entries(score_func_dict)) {
            var data_group = signal_data.filter(d => score_function(d.signalable[signalable_key]));
            signal_groups[signalable_key][score_key] = { 'data': data_group, 'total': data_group.length };
        }
        signal_groups[signalable_key]["average"] = signalable_mean(signal_data, signalable_key);

    }
    return signal_groups;
}



function signal_analytics(data_by_carrier) {
    const signal_type_lookup = {
        'LteSignal': LTE_PERFORMANCE_SCORE,
        'WcdmaSignal': WCDMA_PERFORMANCE_SCORE,
        'NrSignal': NR_PERFORMANCE_SCORE
    }
    let signal_types = {};
    // LteSignal, WCDMDASignal, NRSignal ...
    for (const [signal_type, signal_score_dict] of Object.entries(signal_type_lookup)) {
        var signal_data = data_by_carrier.filter(d => (d["signalable_type"] == signal_type));
        console.log(signal_type)
        // var signal_data = scrub_signals(signal_type, signal_data);
        if (signal_data.length > 0) {

            let signal_avg_lvl = signalable_mean(signal_data, 'level');
            let grouped_signal = analyze_signal_performance(signal_data, signal_score_dict)
            signal_types[signal_type] = grouped_signal;
            signal_types[signal_type]['data'] = signal_data;
            signal_types[signal_type]["average"] = signal_avg_lvl;
            signal_types[signal_type]["total"] = signal_data.length;
            signal_types[signal_type]["avg_str_repr"] = SIGNAL_LEVEL_STR[Math.round(signal_avg_lvl)];

        }
    }
    return signal_types;

}

function extract_data_by_carrier_and_type(data) {
    const carrier_codes = {
        'T_Mobile': T_MOBILE_CODES,
        'AT_and_T': ATandT_CODES,
        'Verizon': VERIZON_CODES
    }
    let carrier_data = {};
    let total_data_size = data.length;
    for (const k of Object.keys(carrier_codes)) {
        const codes = carrier_codes[k];
        let data_by_carrier = get_signals_from_carrier_codes(data, codes);
        if (data_by_carrier.length > 0) {
            let count = data_by_carrier.length;

            let signal_data_obj = signal_analytics(data_by_carrier);
            let sig_stats = signalable_stats(data_by_carrier, 'level');

            carrier_data[k] = signal_data_obj;
            carrier_data[k]['data'] = data_by_carrier;


            carrier_data[k]['avg_str_repr'] = SIGNAL_LEVEL_STR[Math.round(sig_stats.average)];
            carrier_data[k]['total'] = count;
            carrier_data[k]['percent_of_data_collected'] = count / total_data_size;
            carrier_data[k]['stats'] = sig_stats;

        }
    }
    carrier_data['ALL_DATA_STATS'] = signalable_stats(data, 'level');

    return carrier_data;

}

function compute_variance(data_arr, mean) {
    if (!data_arr.length) {
        return 0;
    }
    let variance = 0;
    data_arr.forEach(num => {
        variance += ((num - mean) * (num - mean));
    });
    variance /= data_arr.length;
    return variance;
}


function signalable_stats(signal_data, col) {
    var data_arr = signal_data.map(d => d.signalable[col]);
    let average = compute_average(data_arr);
    let variance = compute_variance(data_arr, average);
    return {
        'average': average,
        'variance': variance,
        'std': Math.sqrt(variance)
    }
}

function signalable_mean(signal_data, col) {
    return compute_average(signal_data.map(d => d.signalable[col]));

}


function get_signals_from_carrier_codes(data, carrier_codes) {
    var data_ = data;
    for (const [key, value] of Object.entries(carrier_codes)) {
        var tmp = data_.filter(d => (d.signalable['mcc'] == key && value.includes(d.signalable['mnc'])));
        if (tmp.length > 0) {
            data_ = tmp;
        }
    }
    return data_
}

// User data => [At&t, T-mobile, ...] => [At&t:[LteSignal, NrSignal...]]
function analyze_user_signal_data(data, user_id) {
    // var user_data = data.filter(u => u.user_id == user_id);
    var user_data = data;
    var user_data = scrub_data(user_data);
    console.log("User " + user_id.toString() + " has collected " + user_data.length.toString() + " cell signals total...");
    const user_data_by_carrier = extract_data_by_carrier_and_type(user_data);
    console.log(user_data_by_carrier.ALL_DATA_STATS);
    // for (const i in CARRIERS) {
    //     let carrier = CARRIERS[i];
    //     let carrier_data = user_data_by_carrier[carrier];

    //     if (carrier_data !== undefined) {
    //         console.log(carrier);
    //         console.log(carrier_data);
    //         // let average = carrier_data.avg_str_repr;
    //         // let total = carrier_data.total;
    //         // let percentage = carrier_data.percent_of_data_collected;
    //         // let variance = carrier_data.variance;
    //         // let std = carrier_data.std;
    //         // let power_avg = carrier_data.power_average;
    //         // console.log(average, total, percentage, variance, std, power_avg);
    //         // console.log(total * percentage);

    //         // for (var signals in carrier_data) {
    //         //     console.log(carrier_data[signals].rsrp);
    //         //     continue;
    //         // }
    //     }
    // };
}

const getCoordinatesFromStringPoint = (point) => {
    let parsedPoint = point.substr(6).split(' ');
    const length = parsedPoint[1].length - 1;
    return [parsedPoint[0].substr(1), parsedPoint[1].substr(0, length)].map(geo => Number(geo));
}

function scrub_data(data) {
    let scrubed_data = data.filter(d => (d.signalable['location'] != null));
    let removed = data.length - scrubed_data.length;
    console.log(removed.toString() + " invalid signals removed...");

    return scrubed_data;
}

function save_data(data) {

    // write JSON string to a file
    //fs.writeFileSync('geojsondata.json', JSON.stringify(data, null, 2), 'utf-8');

}

var metersPerPixel = function (zoomLevel) {
    var earthCircumference = 40075017;
    var latitudeRadians = 35.1283328 * (Math.PI / 180);
    return earthCircumference * Math.cos(latitudeRadians) / Math.pow(2, zoomLevel + 8);
};

var pixelValue = function (meters, zoomLevel) {
    return meters / metersPerPixel(zoomLevel);
};

function convert_to_geojson(data) {
    var data_out = data.map(d => {
        return {
            type: 'feature',
            properties: {
                level: d.signalable['level'],
                rsrp: d.signalable['rsrp'],
                rsrq: d.signalable['rsrq'],
                meters_acc: parseInt(d['location_accuracy'])
            },
            geometry: {
                type: "Point",
                coordinates: getCoordinatesFromStringPoint(d.location)
            }
        }
    });
    let data_geo = {
        'type': 'FeatureCollection',
        'features': data_out
    }
    return data_geo

};


function load_and_scrub_test_data(DATA) {
    var data = scrub_data(DATA);
    var user_data = extract_data_by_carrier_and_type(data);
    return convert_to_geojson(data);
    // return extract_data_by_carrier_and_type(data);
}

function sort_obj(obj, key) {
    let sortable = [];
    for (var k in obj) {
        sortable.push([k, obj[k].rank, obj[k]]);
    }

    return sortable.sort();
}

function signal_str(strs, perf_score, attr, input) {

    for (var f in perf_score[attr]) {
        if (perf_score[attr][f](input)) {
            return strs[f];
        }
    }

}

const lte_quality_strs = {
    "best": 'Average LTE Signal Quality VERY GOOD ',
    "good": 'Average LTE Signal Quality GOOD ',
    "fair": 'Average LTE Signal Quality FAIR ',
    "poor": 'Average LTE Signal Quality POOR ',
    "none_or_unknown": 'Average LTE Signal Quality NONE OR UNKNOWN'
}

function str_dicts(signal_type, attribute) {
    return {
        "best": 'Average ' + signal_type.toString() + ' Signal ' + attribute.toString() + ' VERY GOOD ',
        "good": 'Average '+ signal_type.toString() +' Signal ' + attribute.toString() + ' GOOD ',
        "fair": 'Average '+ signal_type.toString() +' Signal ' + attribute.toString() + ' FAIR ',
        "poor": 'Average '+ signal_type.toString() +' Signal ' + attribute.toString() + ' POOR ',
        "none_or_unknown": 'Average '+ signal_type.toString() +' Signal ' + attribute.toString() + ' NONE OR UNKNOWN'
    }
}


function scrub_signals(signal_type, data) {
    let data_output = data.filter(d => (d.location != null));
    if (signal_type == 'LteSignal') {
        console.log(data.length);
        var tmp = data_output.filter(d => (d.signalable['rsrp'] < UNAVAILABLE && d.signalable['rsrq'] < UNAVAILABLE))
        console.log(tmp.length);
        return tmp;
    }
    else if (signal_type == 'WcdmaSignal') {
        console.log(data_output.length);
        var tmp = data_output.filter(d => (d.dbm < UNAVAILABLE))
        console.log(tmp.length);
        return tmp;
    }
    else if (signal_type == 'NrSignal') {
        var tmp = data_output.filter(d => (d.signalable['ss_rsrp'] < UNAVAILABLE || d.signalable['ss_rsrq'] < UNAVAILABLE))
        return tmp;
    }
    else {
        return data_output;
    }
}

export function carrierStats(cellSignals) {
    
    // var data = scrub_data(DATA);
    var data = cellSignals;
    let carrier_data = extract_data_by_carrier_and_type(data);
    let carrier_stats = {'T_Mobile': {}, 'AT_and_T':{}, 'Verizon':{}};
    for (var i = 0; i < CARRIERS.length; i++) {
        let tmp_carrier = carrier_data[CARRIERS[i]];
        let carrier_str = CARRIERS[i];
        carrier_stats[carrier_str]['overall_level_stats'] = tmp_carrier.stats;
        if (tmp_carrier.WcdmaSignal) {
            var wcdma_dbm = tmp_carrier.WcdmaSignal.dbm.average;
            carrier_stats[carrier_str]['wcdma_analysis'] = {
                'power':
                {
                    'message': signal_str(str_dicts('LTE', 'Power'), WCDMA_PERFORMANCE_SCORE, 'dbm', wcdma_dbm),
                    'average_rsrp_lte': wcdma_dbm
                },
                'quality':
                {
                    'message': "Signal Quality not available for Wcdma Signals...", 
                    'average_rsrq_lte': null 
                }
            }
        }
        else {
            carrier_stats[carrier_str]['wcdma_analysis'] = {
                'quality': {
                   'message': "Signal Quality not available for Wcdma Signals...",
                   'average_dbm': null
               },
               'power': {
                   'message': 'No WCDMA power data available...',
                   'average_dbm': null
               },
               'total': 0
           }
        }

        if (tmp_carrier.LteSignal) {
            var lte_rsrp = tmp_carrier.LteSignal.rsrp.average;

            var lte_rsrq = tmp_carrier.LteSignal.rsrq.average;
            carrier_stats[carrier_str]['lte_analysis'] = {
                'power':
                {
                    'message': signal_str(str_dicts('LTE', 'Power'), LTE_PERFORMANCE_SCORE, 'rsrp', lte_rsrp),
                    'average_rsrp_lte': lte_rsrp
                },
                'quality':
                {
                    'message': signal_str(str_dicts('LTE', 'Quality'), LTE_PERFORMANCE_SCORE, 'rsrq', lte_rsrq), 
                    'average_rsrq_lte': lte_rsrq 
                }
            }
        }
        else {
            carrier_stats[carrier_str]['lte_analysis'] = {
                'quality': {
                   'message': 'No LTE quality data available...',
                   'average_rsrq_lte': null
               },
               'power': {
                   'message': 'No LTE power data available...',
                   'average_rsrp_lte': null
               },
               'total': 0
           }

        }

        if (tmp_carrier.NrSignal) {
            var nr_rsrp = tmp_carrier.NrSignal.ss_rsrp.average;

            var nr_rsrq = tmp_carrier.NrSignal.ss_rsrq.average;
            carrier_stats[carrier_str]['nr_analysis'] = {
                'power':
                {
                    'message': signal_str(str_dicts('NR', 'Power'), NR_PERFORMANCE_SCORE, "ss_rsrp", nr_rsrp),
                    'average_rsrp_nr': nr_rsrp
                },
                'quality':
                    { 
                        'message': signal_str(str_dicts('NR', 'Quality'), NR_PERFORMANCE_SCORE, 'ss_rsrq', nr_rsrq), 
                        'average_rsrq_nr': nr_rsrq 
                },
                'total': tmp_carrier.NrSignal.data.length
            }
        }
        else {
            carrier_stats[carrier_str]['nr_analysis'] = {
                 'quality': {
                    'message': 'No NR quality data available...',
                    'average_rsrq_nr': null
                },
                'power': {
                    'message': 'No NR power data available...',
                    'average_rsrp_nr': null
                },
                'total': 0
            }
        }
    }
    return carrier_stats
}
