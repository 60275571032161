import React, {useState, useEffect} from "react";
import Modal from 'react-modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import ClipLoader from "react-spinners/ClipLoader";

export default function WarningModal(props){
    const {modalIsOpen, setModalIsOpen, afterOpenModal, proceedFunction, closeButtonText,proceedButtonText, mainText, setWarningUser, warningUser, modalTitle, warningIsLoading, warningResultMessage, setWarningResultMessage, setWarningIsLoading} = props;
    console.log('warning result message', setWarningResultMessage)
    return (
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={()=>{
                setModalIsOpen(false);
                setWarningIsLoading(false);
                setWarningResultMessage('')
                setWarningUser({});
    
            }}
            contentLabel="Caution Modal"
            className={'caution-modal flex col justify-space-between padding-bottom-1 padding-top-1'}
        >
            <div className="modal-header flex row justify-center align-start padding-sides-fourth padding-bottom-1">
                <h3 className="font-p-bold">{modalTitle}</h3>
            </div>
            <div className="modal-main max-width-33-rem padding-sides-1">
                <p style={{textAlign: 'center'}}>
                    {!warningIsLoading ?
                        <h5 className='font-p-light' style={{lineHeight: 1.3}}>
                            {warningResultMessage.length > 0 ? warningResultMessage : mainText}
                        </h5>
                    :
                        <ClipLoader />
                    }
                </p>
            </div>
            <div 
                className="modal-action-prompt align-center justify-center padding-top-1 padding-sides-1"
            >
                {!warningIsLoading || warningResultMessage.length != 0 ? <button
                    onClick={(e)=>{
                        e.preventDefault(); 
                        setModalIsOpen(false); 
                        setWarningIsLoading(false);
                        setWarningResultMessage('')        
                        setWarningUser({})
                    }} 
                    className="cancel-button width-100"
                    style={{marginLeft: '10'}}                    
                >
                    <h5 className="font-reg color-white">
                        {warningResultMessage.length != 0 ? 'Close' : closeButtonText}
                    </h5>
                </button> : null}
                {!warningIsLoading && warningResultMessage.length == 0 ? <div style={{width: 50}}></div>:null}
                {!warningIsLoading && warningResultMessage.length == 0 ? <button
                    type="submit"       
                    className="success-button width-100"
                    style={{marginRight: '10'}}
                    onClick={(e)=>{e.preventDefault(); proceedFunction()}}
                >
                    <h5 className="font-reg color-white">
                        {proceedButtonText}
                    </h5>
                </button>: null}
            </div>        
        </Modal>
    )

}