import React, {useState, useEffect} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faEdit, faInfo, faInfoCircle, faTrash, faUserCog, faWindowClose} from '@fortawesome/free-solid-svg-icons';
import { axiosWrapper } from "../../lib/jwt";
import { titleize } from "../../lib/util";
import { useDispatch } from "react-redux";
import { useFormik } from 'formik';
import ClipLoader from "react-spinners/ClipLoader";
import axios from "axios";
import Select from 'react-select'

export default function UserManagementForm(props){
    const {jwts, user, editor, isExpanded, isEditModeOn, closeMethod, isNewUser, submitMethod, setModalIsOpen, setWarningUser, resettable} = props;

    const dispatch = useDispatch();
    const {username, firstName, lastName, email, personalPhoneNumber, institutionName,userType, assignedPhone} = user;
    const editorType = editor.attributes?.userType

    const isSelectDisabled = !(isEditModeOn && editorType == 'super_admin' && user.id != editor.attributes?.id);

    console.log('is select disabled', isSelectDisabled);

    const validate = async values => {
        const errors = {};
        console.log('validation is running')
        if (!values.firstName) {
        errors.firstName = 'Required';
        }else if(values.firstName.length < 2){
            errors.firstName = 'Must be at least 2 characters long';
        }else if (values.firstName.length > 15) {
        errors.firstName = 'Must be 15 characters or less';
        }

        const regex = /^[a-zA-Z0-9_]+$/; 

        if (!values.username) {
            errors.username = 'Required';
        } else if(values.username.length < 6){
            errors.username = 'Must be at least 2 characters long';
        } else if (values.username.length > 19) {
            errors.username = 'Must be 19 characters or less';
        } else if(!regex.test(values.username)){
            errors.username = 'Usernames may only have letters, numbers, or underscores'
        }

        //TODO Make this not send as many http requests to username availiability endpoint

        if(values.username && !errors.username && values.username != username){
            try{
            const usernameAvailable = await axios.get(`/username?username=${values.username}`, {timeout: 500})
                .then(({data})=>{
                    return data.usernameAvailable;
                })
                .catch(e =>{
                    console.log('error', e);
                    //TODO handle this later, must error more gracefully and make a decision for how to handle validation OR have form submission error feedback in a better state than it currently is
                    return true;
                })
            if(!usernameAvailable && values.username != username){
                errors.username = "Username is already taken";
            }
            }catch(e){

            }
        }
        
    
    
        if (!values.lastName) {
        errors.lastName = 'Required';
        } else if(values.lastName.length < 2){
            errors.lastName = 'Must be at least 2 characters long';
        } else if (values.lastName.length > 20) {
        errors.lastName = 'Must be 20 characters or less';
        }
    
        if (!values.email) {
        errors.email = 'Required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address';
        }

        if(!values.personalPhoneNumber){
            errors.personalPhoneNumber = 'Required'
        }else if ( ![10,11].includes(values.personalPhoneNumber.match(/\d/g).length)){
            errors.personalPhoneNumber = "Invalid Phone Number. Must include Area Code"
        }
        console.log('validation errors', errors);
        return errors;
    };


    const submitCallback = (values) =>{
        console.log('submit values', values);
        return axiosWrapper({
            method: `${isNewUser ? "post" : "put"}`,
            accessToken: jwts?.accessToken, 
            refreshToken: jwts?.refreshToken,               
            url: '/user',
            data: {
                id: user.id,
                firstName: values.firstName,
                lastName: values.lastName,
                username: values.username, 
                email: values.email, 
                personalPhoneNumber: values.personalPhoneNumber, 
                assignedPhone: values.assignedPhone,
                userType: values.userType
            }
        }, dispatch)
        .then(({data}) => {
            console.log('submit result', data)
            return data;
        })
        .catch(error => {
            console.log('error editing user', error);
            return error;
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              }
        })
    }

    const formik = useFormik({
        initialValues: {
          firstName: firstName,
          lastName: lastName,
          username,
          email: email,
          institutionName,
          userType,
          personalPhoneNumber,
          assignedPhone
        },
        mapPropsToValues: () => ({ userType: '' }),
        validate,
        onSubmit: values => {
          submitMethod(submitCallback(values));
        },
      });
  

    return (
        <form 
            className="user-row-bottom col expanded width-100"
            style={isExpanded ? null : {height: "0px", padding: 0}}
            onSubmit={formik.handleSubmit}
        >
            <div className="flex row justify-space-around">
                <div className="form-control col flex">
                    <label htmlFor='firstName'>
                        <h6 className="font-reg">First Name</h6>
                    </label>
                    <input 
                        className={`flex ${isEditModeOn ? 'edit-mode' : 'view-mode'}`}
                        id="firstName"
                        name="firstName"
                        type="text"
                        disabled={!isEditModeOn}
                        onChange={formik.handleChange}
                        value={formik.values.firstName}
                        onBlur={formik.handleBlur}
                    />
                    {formik.errors.firstName && formik.touched.firstName ? 
                        <h6 className="small color-red font-p-reg padding-top-fourth">      
                            {formik.errors.firstName}
                        </h6> 
                    : null}
                </div>
                <div className="form-control col flex">
                    <label htmlFor='lastName'>
                        <h6 className="font-reg">Last Name</h6>
                    </label>
                    <input 
                        className={`flex ${isEditModeOn ? 'edit-mode' : 'view-mode'}`}
                        name={'lastName'}
                        disabled={!isEditModeOn}
                        id="lastName"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.lastName}
                        onBlur={formik.handleBlur}
                    />
                    {formik.errors.lastName && formik.touched.lastName ? 
                        <h6 className="small color-red font-p-reg padding-top-fourth">      
                            {formik.errors.lastName}
                        </h6> 
                    : null}
                </div>
            </div>
            <div className="flex row justify-space-around padding-top-1">
                <div className="form-control col flex">
                    <label htmlFor='username'>
                        <h6 className="font-reg">Username</h6>
                    </label>
                    <input 
                        className={`flex ${isEditModeOn ? 'edit-mode' : 'view-mode'}`}
                        disabled={!isEditModeOn}
                        name={'username'}
                        id='username'
                        type={'text'} 
                        onChange={formik.handleChange}
                        value={formik.values.username}
                        onBlur={formik.handleBlur}
                    />
                    {formik.errors.username && formik.touched.username ? 
                        <h6 className="small color-red font-p-reg padding-top-fourth">      
                            {formik.errors.username}
                        </h6> 
                    : null}
                </div>
                <div className="form-control col flex">
                    <label htmlFor='email'>
                        <h6 className="font-reg">Email</h6>
                    </label>
                    <input 
                        className={`flex ${isEditModeOn ? 'edit-mode' : 'view-mode'}`}
                        name={'email'}
                        id='email'
                        disabled={!isEditModeOn}
                        type={'text'} 
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        onBlur={formik.handleBlur}
                    />
                    {formik.errors.email && formik.touched.email ? 
                        <h6 className="small color-red font-p-reg padding-top-fourth">      
                            {formik.errors.email}
                        </h6> 
                    : null}

                </div>
            </div>
            <div className="flex row justify-space-around padding-top-1">
                <div className="form-control col flex">
                    <label htmlFor='personalPhoneNumber'>
                        <h6 className="font-reg">Phone Number</h6>
                    </label>
                    <input 
                        className={`flex ${isEditModeOn ? 'edit-mode' : 'view-mode'}`}
                        name={'personalPhoneNumber'}
                        disabled={!isEditModeOn}
                        type={'tel'} 
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.personalPhoneNumber}
                    />
                    {formik.errors.personalPhoneNumber && formik.touched.personalPhoneNumber ? 
                        <h6 className="small color-red font-p-reg padding-top-fourth">      
                            {formik.errors.personalPhoneNumber}
                        </h6> 
                    : null}
                </div>
                <div className="form-control col flex select-container">
                    <label htmlFor='username'>
                        <h6 className="font-reg">User Role</h6>
                    </label>
                    <select
                        name={'userType'}
                        disabled={isSelectDisabled}
                        value={formik.values.userType}
                        onChange={formik.handleChange}
                        className={`flex ${isEditModeOn ? 'edit-mode' : 'view-mode'}`}
                    >
                        <option value={"super_admin"}>Super Admin</option>
                        <option value={"admin"}>Admin</option>
                        <option value={"client"}>Client</option>
                    </select>
                </div>
            </div>
            <div className="flex padding-top-1 row" style={{width: "50%", paddingLeft: "0px !important", paddingRight: 0}}>
                <div className="form-control col flex" style={{paddingLeft: 0}}>
                    <label htmlFor='assignedPhone'>
                        <h6 className="font-reg">Assigned Phone Id</h6>
                    </label>
                    <input
                        disabled={!isEditModeOn}
                        className={`flex ${isEditModeOn ? 'edit-mode' : 'view-mode'}`}
                        id='assignedPhone'
                        name={'assignedPhone'} 
                        type={'text'} 
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.assignedPhone}
                    />
                </div>
            </div>
            {isEditModeOn && user.id != -1 && resettable ? <div 
                className="flex padding-top-1 row" 
                style={{width: "50%", paddingLeft: "0px !important", paddingRight: 0}}
            >
                <button       
                    className="caution-button width-100"
                    onClick={(e)=>{
                        e.preventDefault();
                        setWarningUser({...user})
                        setModalIsOpen(true);
                    }}
                >
                    <h5 className="font-reg color-white">
                        Reset Password
                    </h5>
                </button>
            </div>: null}
            {isEditModeOn ? <div className="flex padding-top-2 row align-center justify-center">
                <div 
                    className="form-control align-center justify-center flex"
                    style={{paddingLeft: 0}}
                >
                    <button
                        type="submit"       
                        className="user-save-button width-100"
                    >
                        <h5 className="font-reg color-white">
                            Save
                        </h5>
                    </button>
                </div>
                <div className="form-control align-center justify-center flex">
                    <button
                        onClick={(e)=>{e.preventDefault(); closeMethod()}} 
                        className="user-close-button width-100"                       
                    >
                        <h5 className="font-reg color-white">
                            Close
                        </h5>
                    </button>
                </div>
            </div> : null}
        </form>
    )
}