import React, {useState, useEffect} from "react";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faEdit, faInfo, faInfoCircle, faTrash, faUserCog, faWindowClose} from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux'
import { userLogin } from "../../reducers/UserSlice";
import Navigation from '../Navigation';
import { titleize } from "../../lib/util";
import ClipLoader from "react-spinners/ClipLoader";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import PaginationBar from "../util/PaginationBar";
import ExpandedPointRow from "./ExpandedPointRow";
import { useRequest } from "../../lib/hooks";

export default function PriorityPointsManagement(){
    const [priorityPoints, setPriorityPoints] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchBy, setSearchBy] = useState('location');
    const [totalCount, setTotalCount] = useState(0);
    const [page, setPage] = useState(1);
    const {axiosWrapper, dispatch, jwts, user} = useRequest();
    const [creationSuccessMessageOn, setCreationSuccessMessageOn] = useState(false);
    const institutionName = user?.attributes?.institutionName;

    const deletePriorityPoint = (priorityPointId) =>{
        const newPriorityPoints = priorityPoints.filter(p => p.id != priorityPointId);
        setPriorityPoints([...newPriorityPoints]);
    }

    useEffect(()=>{
        if(searchQuery.length > 0){
            axiosWrapper({
                method: 'get',
                accessToken: jwts?.accessToken, 
                refreshToken: jwts?.refreshToken,               
                url: `/priority-points?query=${searchQuery}&searchBy=${searchBy}&page=${page}`
            }, dispatch)
            .then(({data}) => {
                console.log('POINTS', data)
                setPriorityPoints([...data.priorityPoints])
                setTotalCount(data.totalCount)
            })
            .catch(e => {
                console.log('error getting user-data', e)
            })
        }else{
            axiosWrapper({
                method: 'get',
                accessToken: jwts?.accessToken, 
                refreshToken: jwts?.refreshToken,               
                url: `/priority-points?searchBy=${searchBy}&page=${page}`
            }, dispatch)
            .then(({data}) => {
                setPriorityPoints([...data.priorityPoints])
                setTotalCount(data.totalCount)
            })
            .catch(e => {
                console.log('error getting user-data', e)
            })
    
        }
    }, [searchQuery, page, searchBy])

    const options = [{label: 'Search By Location Name', value: 'location'},{label: 'Search By Client', value: 'client'}];
    console.log('total count', totalCount)

    return (
        <div 
            className='flex flex-col settings-container align-center'
            style={{backgroundImage: 'url("/images/techno-fabric-background.svg")'}}
        >
            <Navigation />
            <div className="settings-content flex col">
                <div className="width-100 align-center justify-center padding-top-2 padding-bottom-2">
                    <h1 className='font-p-reg'>Priority Points</h1>
                </div>
                <div 
                    className='search-by-container align-center justify-center margin-top-1'
                >
                    <Dropdown 
                        options={options} 
                        onChange={(e)=>{
                            console.log('event', e)
                            setSearchBy(e.value)
                        }} 
                        value={searchBy} 
                        controlClassName='search-by-dropdown font-p-bold' 
                        placeholder="Select an option"
                        menuClassName='search-by-dropdown-menu font-p-bold'
                    />
                    <input 
                        type='text' 
                        className="search-by-field font-p-bold"
                        placeholder="Search Term"
                        onChange={(e)=>{setSearchQuery(e.target.value)}}
                        value={searchQuery}
                    >
                    </input>
                </div>
                <div className="priority-point-pagination">
                    <PaginationBar 
                        totalPages={totalCount} 
                        currentPage={page}
                        setPage={setPage}
                    />
                </div>
                <div className='users-container width-100 justify-center col padding-2'>
                    {  priorityPoints.sort((p1, p2) =>{
                            return (p1.id < p2.id) ? -1 : (p1.id > p2.id) ? 1 : 0;
                        })
                        .map(priorityPoint=>{
                        return (
                            <PriorityPointRow 
                                jwts={jwts} 
                                priorityPoint={priorityPoint}
                                deletePriorityPoint={deletePriorityPoint}
                                dispatch={dispatch}
                                institutionName={institutionName}

                            />
                        )
                    })}
                </div>        
            </div>
        </div>
    )
}

const DeletionRow = (props) =>{
    const {priorityPoint, setDeletePromptOn, deleteMethod, displayName} = props;
    
    return (
        <div className='user-row col align-center justify-center col'>
            <div className="user-row-top row align-center justify-space-between width-100">
                <div className="user-row-description">
                    <h4 className="font-bold">
                        {priorityPoint.name}
                    </h4>
                    <h6 className='font-light'>
                        {displayName}
                    </h6>
                </div>
                <div className="col justify-space-between padding-1">
                    <h5 className="font-p-reg padding-bottom-1" style={{textAlign: 'center'}}>
                        Are you sure you want to delete this user?
                    </h5>
                    <div className='row align-center justify-center'>
                        <button 
                            className="delete-button-outline" 
                            style={{marginRight: 10}}
                            onClick={(e)=>{
                                setDeletePromptOn(false)
                            }}
                        >
                            <h5 className='font-p-reg'>
                                Cancel
                            </h5>
                        </button>
                        <button 
                            className="delete-button"
                            style={{marginLeft: 10}}
                            onClick={(e)=>{
                                setDeletePromptOn(false)
                                deleteMethod()
                            }}
                        >
                            <h5 className='font-p-reg  color-light'>
                                Delete
                            </h5>
                        </button>
                    </div>
                </div>
            </div>
        </div>

    )
}

const LoadingRow = (props)=>{
    const {priorityPoint, displayName} = props;
    
    return (
        <div className='user-row col align-center justify-center'>
            <div className="user-row-top row align-center justify-space-between width-100">
                <div className="user-row-description">
                    <h4 className="font-bold">
                        {priorityPoint.name}
                    </h4>
                    <h6 className='font-light'>
                        {displayName}
                    </h6>
                </div>
                <ClipLoader />
            </div>
        </div>
    )
}

function PriorityPointRow(props){
    const {priorityPoint, jwts, deletePriorityPoint, dispatch, institutionName} = props;
    const [isExpanded, setIsExpanded] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [deletePromptOn, setDeletePromptOn] = useState(false);
    const [message, setMessage] = useState('');
    
    const determineDisplayName = (priorityPoint, institutionName) =>{
        if(priorityPoint.userId && priorityPoint.user){
            return `${priorityPoint.user.firstName} ${priorityPoint.user.lastName}`
        }else{
            return `${institutionName}`
        }
    }

    const determineDisplayUsername = (priorityPoint) =>{
        if(priorityPoint.userId && priorityPoint.user){
            return `${priorityPoint.user.username}`
        }else{
            return `Administrator`
        }
    }


    const deleteMethod = ()=>{
        setDeletePromptOn(false);
        setIsLoading(true);
        axiosWrapper({
            method: "delete",
            accessToken: jwts?.accessToken, 
            refreshToken: jwts?.refreshToken,               
            url: `/priority-points?id=${priorityPoint.id}`,
        }, dispatch)
        .then(({data}) => {
            console.log('delete result', data)
            setTimeout(()=>{
                console.log('basado');
                deletePriorityPoint(data.deletedPriorityPoint)
                setIsLoading(false);
            }, 1500)
        })
        .catch(e => {
            console.log('error deleting priority point', e)
            setIsLoading(false);
        })
    }

    if(isLoading){
        return <LoadingRow priorityPoint={priorityPoint} displayName={determineDisplayName(priorityPoint, institutionName)} />
    }

    if(deletePromptOn){
        return <DeletionRow priorityPoint={priorityPoint} setDeletePromptOn={setDeletePromptOn} deleteMethod={deleteMethod} displayName={determineDisplayName(priorityPoint, institutionName)}/>
    }

    return (
        <div className='user-row col align-center justify-center col'>
            <div className="user-row-top row align-center col justify-space-between width-100">
                <div className="user-row-description">
                    <h4 className="font-bold">
                        {priorityPoint.name}
                    </h4>
                    {message.length > 0 ? <h6 className={`badge success font-p-bold dark-color`}>
                        {message}
                    </h6> : null} 
                    <h6 className='font-light'>
                        {determineDisplayName(priorityPoint, institutionName)}
                    </h6>
                </div> 
                <>
                <div className="user-row-icons">
                    <FontAwesomeIcon 
                        style={{marginRight: '1rem', cursor: 'pointer'}} 
                        color={'hsl(197, 92%, 61%)'} 
                        icon={faInfoCircle} 
                        size="2x"
                        onClick={(e)=>{
                            setIsExpanded(!isExpanded);
                        }}
                    />
                    <FontAwesomeIcon 
                        style={{cursor: 'pointer'}} 
                        icon={faTrash} 
                        size="2x" 
                        color={'hsl(360, 83%, 62%)'}
                        onClick={(e)=>{
                            setDeletePromptOn(true);
                        }}
                    />
                </div>
                </>
            </div>
            <div
                className="user-row-bottom col expanded width-100"
                style={isExpanded ? null : {height: "0px", padding: 0}}
            >
                <ExpandedPointRow priorityPoint={priorityPoint} isExpanded={isExpanded}/>
            </div>
        </div>
    )
}