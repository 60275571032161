import React, {useState, useEffect} from "react";
import { useParams } from 'react-router-dom';
import Navigation from '../Navigation';
import Map from '../Map/index';
import ControlPanel from './ControlPanel';
import { useCheckboxState } from 'pretty-checkbox-react';
import {carriers} from '../../lib/carriers';
import dateFormat from "dateformat";
import { useSelector, useDispatch } from 'react-redux';
import PriorityPointsTable from './PriorityPointsTable';
import {parseCellSignals, generateFilterObject} from '../../lib/geo';
import {getCoordinatesFromStringPoint} from '../../lib/geo';
import {useRequest} from '../../lib/hooks';

function User(){
    let {id} = useParams();
    const [filters, setFilters] = useState({
        signalTypes: ['NrSignal', 'CdmaSignal', 'WcdmaSignal', 'GsmSignal', 'LteSignal'],
        carriers: ['at-t','verizon','t-mobile'],
        strengths: ['poor' ,'fair', 'good', 'best'],
        startDate: (new Date(Date.now() - + 7 * 24 * 60 * 60 * 1000)),
        endDate: (new Date())
    });
    const [isLoading, setIsLoading] = useState(false);
    const dateModeCheckbox = useCheckboxState();
    const [dateMode, setDateMode] = useState(false);
    const [cellSignals, setIdFilteredCellSignals] = useState([]);
    const {dispatch, user, axiosWrapper, jwts} = useRequest();
    const [priorityPoints, setPriorityPoints] = useState([]);
    const [highlightedPointId, setHighlightedPointId] = useState(-1);
    const [viewport, setViewport] = useState({
        width: "100%",
        height: "70vh",
        latitude: user.attributes?.institutionCenter?.length > 0 ? getCoordinatesFromStringPoint(user?.attributes.institutionCenter)[1] : null, 
        longitude: user.attributes?.institutionCenter?.length > 0 ? getCoordinatesFromStringPoint(user?.attributes.institutionCenter)[0] : null,
        zoom: 10,
    });

    function setCellSignals(newCellSignals){
        const uniqueIds = [];
        const filteredSignals = newCellSignals.filter(signal =>{

            const isDuplicate = uniqueIds.includes(signal.id);

            if (!isDuplicate) {
                uniqueIds.push(signal.id);

                return true;
            }

            return false;
        })

        setIdFilteredCellSignals(filteredSignals);
    }



    const addSignals = (lat, lon)=>{
        let dateQuery = ''
        if(dateMode){
            try{
                const earlierDate = filters.startDate < filters.endDate ? filters.startDate : filters.endDate;
                const laterDate = filters.startDate > filters.endDate ? filters.startDate : filters.endDate;
                const earlierDateQuery = dateFormat(earlierDate, "mm-dd-yyyy");
                const laterDateQuery = dateFormat(laterDate, "mm-dd-yyyy");
                dateQuery = `&start-date=${earlierDateQuery}&end-date=${laterDateQuery}`;
            }catch(e){
                console.log('error setting up date query', e);
            }
        }
        setIsLoading(true);
        axiosWrapper({
            method: 'get',
            accessToken: jwts?.accessToken,        
            refreshToken: jwts?.refreshToken,
            url: `/cell-signals?user-id=${id}&lat=${lat}&lon=${lon}${dateQuery}`
        }, dispatch)
            .then(({data})=>{
                const cellSignalIds = data.cellSignals.map(cs => cs.id)
                setIsLoadingFalse()
                setCellSignals([...cellSignals,...parseCellSignals(data.cellSignals.filter(cs => cellSignalIds.includes(cs.id)))]);
            })
            .catch(e =>{
                setIsLoadingFalse()
                console.log('error getting cell signals', e)

            });
    }

    useEffect(()=>{
        try{
            if(user?.attributes?.institutionCenter){
                const [lon, lat] = getCoordinatesFromStringPoint(user.attributes.institutionCenter)
                if(lat && lon){
                    setViewport({
                        width: "100%",
                        height: "70vh",
                        latitude: lat, 
                        longitude: lon,
                        zoom: 10,        
                    });
                }
            }
        }catch(e){
            console.log('ERROR SETTING', e)
        }

    }, [user])

    useEffect(()=>{
        if(dateModeCheckbox.state){
            
        }else{
            if(dateMode & viewport.latitude & viewport.longitude){
                setIsLoading(true);
                axiosWrapper({
                    method: 'get',
                    accessToken: jwts?.accessToken, 
                    refreshToken: jwts?.refreshToken,               
                    url: `/cell-signals?user-id=${id}&lat=${viewport.latitude}&lon=${viewport.longitude}`
                }, dispatch)
                .then(({data}) => {
                    setIsLoadingFalse();
                    setCellSignals([...parseCellSignals(data.cellSignals)]);
                })
                .catch(e => {
                    setIsLoadingFalse();
                    console.log('error getting cell signals', e)
                })
                setDateMode(false);
            }
        }
        
        return () =>{

        }
    }, [dateModeCheckbox, jwts, user])

    const handleDateModeEnter = (startDate, endDate)=>{
        const newFilters = filters;
        newFilters.startDate = startDate;
        newFilters.endDate = endDate;
        const earlierDate = startDate < endDate ? startDate : endDate;
        const laterDate = startDate > endDate ? startDate : endDate;
        setFilters({...newFilters})
        setDateMode(true);
        if(viewport.latitude && viewport.longitude){
            setIsLoading(true);
            axiosWrapper({
                method: 'get',
                accessToken: jwts?.accessToken,        
                refreshToken: jwts?.refreshToken,
                url: `/cell-signals?user-id=${id}&start-date=${dateFormat(earlierDate, "mm-dd-yyyy")}&end-date=${dateFormat(laterDate, "mm-dd-yyyy")}&lat=${viewport.latitude}&lon=${viewport.longitude}`
            }, dispatch)
                .then(({data})=>{
                    setIsLoadingFalse();
                    setCellSignals([...parseCellSignals(data.cellSignals)]);
                })
                .catch(e =>{
                    setIsLoadingFalse();
                    console.log('error getting cell signals', e)   
                });
        }
        
    }

    useEffect(()=>{
        setIsLoading(true);
        axiosWrapper({
            method: 'get',
            accessToken: jwts?.accessToken,        
            refreshToken: jwts?.refreshToken,
            url: `/user-priority-points/${id}`
        }, dispatch)
            .then(({data})=>{
                setIsLoadingFalse();
                setPriorityPoints([...data.priorityPoints.map(priorityPoint=>{
                    priorityPoint.coordinates = getCoordinatesFromStringPoint(priorityPoint.location)
                    return priorityPoint;
                })]);
            })
            .catch(e =>{
                setIsLoadingFalse();
                console.log('error getting priority points', e)
            });
    },[])

    useEffect(() => {
        //TODO: This will trigger requests when JWTs get refreshed, make sure to fix this in the future
        if(user?.attributes?.institutionCenter){
            const [lon, lat] = getCoordinatesFromStringPoint(user.attributes.institutionCenter)
            setIsLoading(true);
            axiosWrapper({
                method: 'get',
                accessToken: jwts?.accessToken,
                refreshToken: jwts?.refreshToken,    
                url: `/cell-signals?user-id=${id}&lat=${lat}&lon=${lon}`
            }, dispatch)
              .then(({data}) => {
                setIsLoadingFalse();
                setCellSignals([...parseCellSignals(data.cellSignals)]);
              })
              .catch(e => {
                setIsLoadingFalse();
                console.log('error getting cell signals', e)
              })    
        }
      return () => {
          //cleanup
      }
    }, [jwts, user])

    const setIsLoadingFalse = ()=>{
        setTimeout(()=>{
            setIsLoading(false);
        }, 500)
    }

    return (
       <div className='admin-panel-container'>
            <Navigation />
            <div className='map-section'>
                <div className='map'>
                    {viewport.longitude ? <Map 
                        data={cellSignals}
                        isLoading={isLoading}
                        filters={filters}
                        startingPoint={user?.attributes?.institutionCenter} 
                        mode='cell-signals'
                        addSignals={addSignals}
                        viewport={viewport}
                        setViewport={setViewport}
                        priorityPoints={priorityPoints}
                        highlightedPointId={highlightedPointId}
                        caption={`Showing Signals Collected for User ${id}`} 
                    /> : null}
                </div>
                <ControlPanel
                    filters={filters}
                    setFilters={setFilters}
                    dateModeCheckbox={dateModeCheckbox}
                    dateMode={dateMode}
                    handleDateModeEnter={handleDateModeEnter}
                />
            </div>
            <div className="divider"></div>
            <PriorityPointsTable 
                highlightedPointId={highlightedPointId}
                setHighlightedPointId={setHighlightedPointId}
                priorityPoints={priorityPoints}
            />
        </div>
    )
}

export default User;