//format: mccmnc 

export const carriers = {
        "311271": "verizon",
        "311287": "verizon",
        "311276": "verizon",
        "311481": "verizon",
        "310013": "verizon",
        "311281": "verizon",
        "311486": "verizon",
        "311270": "verizon",
        "311286": "verizon",
        "311275": "verizon",
        "311480": "verizon",
        "310012": "verizon",
        "311280": "verizon",
        "311485": "verizon",
        "311110": "verizon",
        "311285": "verizon",
        "311274": "verizon",
        "311390": "verizon",
        "310010": "verizon",
        "311279": "verizon",
        "311484": "verizon",
        "310910": "verizon",
        "311284": "verizon",
        "311489": "verizon",
        "311273": "verizon",
        "311289": "verizon",
        "310004": "verizon",
        "311278": "verizon",
        "311483": "verizon",
        "310890": "verizon",
        "311283": "verizon",
        "311488": "verizon",
        "311272": "verizon",
        "311288": "verizon",
        "311277": "verizon",
        "311482": "verizon",
        "310590": "verizon",
        "311282": "verizon",
        "311487": "verizon",
        "310260": "t-mobile",
        "310200": "t-mobile",
        "310250": "t-mobile",
        "310160": "t-mobile",
        "310240": "t-mobile",
        "310660": "t-mobile",
        "310230": "t-mobile",
        "310031": "t-mobile", //this is listed as 310-31 in the source we are using, anticipate what this value will look like irl in the future
        "310220": "t-mobile",
        "310270": "t-mobile",
        "310210": "t-mobile",
        "310300": "t-mobile",
        "310280": "t-mobile",
        "310330": "t-mobile",
        "310800": "t-mobile",
        "310310": "t-mobile",
        "310410": "at-t",
        "310380": "at-t",
        "310170": "at-t",
        "310150": "at-t",
        "310680": "at-t",
        "310070": "at-t",
        "310560": "at-t",
        "310980": "at-t"
}