import React, {useState, useEffect} from "react";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faAngleRight, faAngleDoubleRight, faAngleLeft, faAngleDoubleLeft} from '@fortawesome/free-solid-svg-icons';
import { axiosWrapper } from "../../lib/jwt";
import { useSelector, useDispatch } from 'react-redux'
import { userLogin } from "../../reducers/UserSlice";
import Navigation from '../Navigation';
import { titleize } from "../../lib/util";
import ClipLoader from "react-spinners/ClipLoader";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

export default function PaginationBar(props){
    const {currentPage, totalPages, setPage} = props;
    function generateMainPages(){
        //beginningCase 1
        if(currentPage <= 2){

            if(totalPages >= 5){
                console.log('HERE 1')
                return [1,2,3,4,5]
            }else{
                console.log('HERE 2')
                return [...Array(totalPages + 1).keys()].filter(i => i !=0)
            }
            
        }else if(totalPages > 5 && currentPage > 2 && (totalPages - currentPage) > 2){
            console.log('HERE 3')
            return [currentPage - 2, currentPage - 1, currentPage, currentPage + 1, currentPage + 2]
        }else{
            console.log('HERE 4')

            return [totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages].filter(i => i != 0)
        }
    }

    return(
        <div className="flex row pagination-bar justify-space-between">
            <div className="flex justify-center align-center">
                <FontAwesomeIcon
                    className={`pagination-icon ${1 != currentPage ? '': 'pagination-disabled'}`}
                    size="2x" 
                    icon={faAngleDoubleLeft}
                    onClick={()=>{
                        if(1 != currentPage){
                            setPage(1);
                        }
                    }}
                />
                <FontAwesomeIcon
                    className={`pagination-icon ${1 != currentPage ? '': 'pagination-disabled'}`}
                    size="2x" 
                    icon={faAngleLeft}
                    style={{marginLeft: "1rem"}}
                    onClick={()=>{
                        if(1 != currentPage){
                            setPage(currentPage - 1);
                        }
                    }}

                />
            </div>
            <div className="flex pagination-center align-center justify-center">
                {totalPages > 0 ? generateMainPages().map(page =>{
                    return (
                        <div 
                            className={`pagination-page font-p-bold no-highlight ${page == currentPage ? 'pagination-selected' : ''}`}
                            onClick={()=>{
                                if(page != currentPage){
                                    setPage(page);
                                }
                            }}
                        >
                            {page}
                        </div>
                    )
                }) : 
                <div 
                    className={`pagination-page`}
                >
                    No Results
                </div>}  
            </div>
            <div className="flex justify-center align-center">
                <FontAwesomeIcon 
                    className={`pagination-icon ${totalPages != currentPage ? '': 'pagination-disabled'}`}
                    size="2x" 
                    icon={faAngleRight}
                    style={{marginRight: "1rem"}}
                    onClick={()=>{
                        if(totalPages != currentPage){
                            setPage(currentPage + 1);
                        }
                    }}
                />
                <FontAwesomeIcon 
                    className={`pagination-icon ${totalPages != currentPage ? '': 'pagination-disabled'}`}
                    size="2x"
                    icon={faAngleDoubleRight}
                    onClick={()=>{
                        if(totalPages != currentPage){
                            setPage(totalPages);
                        }
                    }}
                />
            </div>

            
        </div>
    )


}
