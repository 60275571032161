import {carriers} from './carriers';

export const getCoordinatesFromStringPoint = (point)=>{
    let parsedPoint = point.substr(6).split(' ');
    const length = parsedPoint[1].length - 1;
    return [parsedPoint[0].substr(1), parsedPoint[1].substr(0, length)].map(geo => Number(geo)); 
}

export const parseCellSignals = (cellSignals) =>{
    const newCellSignals = cellSignals.map(cs =>{
        cs.carrier =  carriers[`${cs.mcc}${cs.mnc}`]
        return cs;
    })
    
    return newCellSignals;
}
