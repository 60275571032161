import React from 'react';
import {Source, Layer} from 'react-map-gl';
import {highlightedPointLayer, priorityPointLayer} from './PointLayer';

export function generatePriorityPoints(highlightedPointId, priorityPoints){
    const priorityPointFeatures = priorityPoints.filter(pp => pp.id != highlightedPointId).map(priorityPoint =>{
        return {
            type: 'feature',
            properties:{
                name: priorityPoint.name,
                id: priorityPoint.id
            },
            geometry: { 
                type: "Point", 
                coordinates: priorityPoint.coordinates
            }
        }
    });
    
    const highlightedPoint = priorityPoints.find(priorityPoint =>{
        return priorityPoint.id == highlightedPointId
    });

    const priorityPointFeatureCollection = {
        type: "FeatureCollection",
        features: priorityPointFeatures,
    }

    return (
        <>
            {highlightedPoint ? 
                <Source 
                    type="geojson" 
                    data={{
                        "type": "Feature",
                        "geometry": {
                            "type": "Point",
                            "coordinates": highlightedPoint.coordinates
                        },
                        "properties": {
                            name: highlightedPoint.name,
                            id: highlightedPoint.id,    
                        }
                    }}
                >
                    <Layer {...highlightedPointLayer} />
                </Source> 
            : null}
            <Source type="geojson" data={priorityPointFeatureCollection}>
                <Layer {...priorityPointLayer}/>
            </Source>
        </>
    )
}