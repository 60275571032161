import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { axiosWrapper } from '../../lib/jwt'
import dateFormat from 'dateformat'
import { signalModelToString } from '../../lib/util'
export default function PopupData(props) {
    const { features } = props
    const jwts = useSelector((state) => state.jwt)
    const dispatch = useDispatch()
    const [popupFeatures, setPopupFeatures] = useState([])

    useEffect(() => {
        axiosWrapper(
            {
                method: 'post',
                accessToken: jwts?.accessToken,
                refreshToken: jwts?.refreshToken,
                url: `/cell-signals-multi`,
                data: {
                    ids: features
                        .filter((feature) => feature.type == 'cell-signal')
                        .map((feature) => feature.id),
                },
            },
            dispatch
        )
            .then(({ data }) => {
                setPopupFeatures({
                    cellSignals: [...data.cellSignals],
                    priorityPoints: features.filter(
                        (feature) => feature.type == 'priority-point'
                    ),
                })
            })
            .catch((e) => {
                console.log('error getting cell signals', e)
            })

        return () => {}
    }, [features])

    return (
        <div
            className="popup-data"
            onScroll={(e) => {
                e.stopPropagation()
            }}
        >
            {popupFeatures?.priorityPoints?.map((priorityPoint) => {
                return <PopupPriorityPoint {...priorityPoint} />
            })}
            {popupFeatures?.cellSignals?.map((cs) => {
                return <PopupCellSignal {...cs} />
            })}
        </div>
    )
}

function PopupCellSignal(props) {
    const { id, dbm, signalable, signalableType, collectedAt } = props

    const getSignalable = () => {
        return Object.entries(signalable).filter(
            ([key, value]) =>
                ![
                    'collectedAt',
                    'createdAt',
                    'isServingCell',
                    'updatedAt',
                    'location',
                ].includes(key) && value != null
        )
    }

    return (
        <div className="popup-content">
            <h4>{signalModelToString(signalableType)}</h4>
            <h5>{dbm} dbms</h5>
            <h5>{dateFormat(collectedAt, 'm/d/yyyy')}</h5>
            <h5>{dateFormat(collectedAt, 'hh:MM TT')}</h5>
            {getSignalable().map((pair) => {
                return (
                    <div className="popup-text">
                        <h6>{pair[0]}</h6>
                        <h6>{Array.isArray(pair[1]) && pair[1].length > 1 ? pair[1].join(',') : pair[1]}</h6>
                    </div>
                )
            })}
        </div>
    )
}

function PopupPriorityPoint(props) {
    const { properties } = props
    return (
        <div className="popup-content">
            <h4 className='popup-priority-title'>Priority Point</h4>
            <div className="popup-text">
                <h5>id</h5>
                <h5>{properties?.id}</h5>
            </div>
            <div className="popup-text">
                <h5>name</h5>
                <h5>{properties?.name}</h5>
            </div>
        </div>
    )
}
