import React from 'react'

export default function DocumentationTable({ data }) {
    return (
        <table className="documentation-table">
            <thead>
                <tr>
                    <th className='font-p-semibold'>NAME</th>
                    <th className='font-p-semibold'>FORMAT</th>
                    <th className='font-p-semibold'>DESCRIPTION</th>
                    <th className='ref-header-cell font-p-semibold'>REFERENCE</th>
                    <th className='font-p-semibold'>ANDROID VERSION</th>
                </tr>
            </thead>
            <tbody>
                {data.map((signalDatum, index) => {
                    return (
                        <tr key={`${signalDatum.name}-${index}`}>
                            <td className='doc-name-cell font-p-semibold'>{signalDatum.name}</td>
                            <td className='format-name-cell font-p-semibold'>{signalDatum.format}</td>
                            <td className='desc-name-cell font-p-semibold'>{signalDatum.description}</td>
                            <td className='ref-name-cell font-p-semibold'>{signalDatum.reference}</td>
                            <td className='droid-name-cell font-p-semibold'>{signalDatum.version}</td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}
