import { hot } from 'react-hot-loader/root';
import React from 'react'
import store from '../reducers/store';
import { Provider } from 'react-redux'
import { getTokensFromStorage, isRefreshValid } from '../lib/jwt';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import AdminPanel from '../components/AdminPanel';
import Login from '../components/Login';
import MapSettings from '../components/Settings/MapSettings';
import UserSettings from '../components/Settings/UserSettings';
import UserManagement from '../components/Settings/UserManagement';
import PriorityPoints from '../components/PriorityPoints';
import User from '../components/AdminPanel/User';
import CarrierPanel from '../components/CarrierMap';
import UserDocumentation from '../components/UserDocumentation';
import DemoInformation from '../components/UserDocumentation/DemoInformation';
import MobileDocumentation from '../components/UserDocumentation/MobileDocumentation'
import PortalDocumentation from '../components/UserDocumentation/PortalDocumentation'

  const tokens = getTokensFromStorage();
  isRefreshValid(tokens.refreshDate)

  class App extends React.Component{
    render(){
      return (
        <Provider store={store}>
          <Router>
            <Switch>
              <Route exact path="/portal-docs">
                <PortalDocumentation />
              </Route>
              <Route exact path="/mobile-docs">
                <MobileDocumentation />
              </Route>
              <Route exact path="/demo-information">
                <DemoInformation />
              </Route>
              <Route exact path="/user-docs">
                <UserDocumentation />
              </Route>
              <Route exact path="/admin-panel">
                <AdminPanel />
              </Route>
              <Route exact path='/user-management'>
                <UserManagement />
              </Route>
              <Route exact path='/map-settings'>
                <MapSettings />
              </Route>
              <Route exact path='/user-settings'>
                <UserSettings />
              </Route>
              <Route exact path='/user-analysis/:id'>
                <User />
              </Route>
              <Route exact path='/priority-locations'>
                <PriorityPoints />
              </Route>
              <Route exact path='/carrier-map'>
                <CarrierPanel />
              </Route>
              <Route exact path='/login'>
               {isRefreshValid(tokens.refreshDate) ?
                  <AdminPanel /> 
                  :
                  <Login />
                }
              </Route>
              <Route exact path='/'>
               {isRefreshValid(tokens.refreshDate) ?
                  <AdminPanel /> 
                  :
                  <Login />
                }
              </Route>
            </Switch>
          </Router>
        </Provider>
      )
    }
  }
    
  export default hot(App);
