import React, {useState} from 'react';
import DateModeOption from './CarrierDateModeOption';
import ClipLoader from "react-spinners/ClipLoader"; 

function CarrierControlPanel(props) {
  const {
    dateMode,
    dateModeCheckbox,
    handleDateModeEnter,
    filters,
    setFilters,
    analyzeCarriers
  } = props;
  
  const [runAnalysisLoading, setRunAnalysisLoading] = useState(false);
  const [analysisResult, setAnalysisResult] = useState([]);
  const handleSignalToggle = (signalName)=>{
    let newSignalTypes; 
    if(filters.signalTypes.includes(signalName)){
      newSignalTypes = filters.signalTypes.filter(x => x != signalName);
    }else{
      newSignalTypes = filters.signalTypes;
      newSignalTypes.push(signalName);
    }

    let newFilters = filters;
    newFilters.signalTypes = newSignalTypes;
    setFilters({...newFilters});
  }

  const handleCarrierToggle = (carrierName)=>{
    let newCarriers; 
    if(filters.carriers.includes(carrierName)){
      newCarriers = filters.carriers.filter(x => x != carrierName);
    }else{
      newCarriers = filters.carriers;
      newCarriers.push(carrierName);
    }

    let newFilters = filters;
    newFilters.carriers = newCarriers;
    setFilters({...newFilters});
  }

  const carrierComparisonClicked = ()=>{
    
    setRunAnalysisLoading(true);

    analyzeCarriers()
    .then(result =>{
      console.log('ANALYZE RESULT', result);
      setTimeout(() => {
        setRunAnalysisLoading(false);
        setAnalysisResult([...result]);
      }, 1000);
    })
    .catch(e=>{
      console.log('ERROR', e);
      setTimeout(() => {
        setRunAnalysisLoading(false);
      }, 1000);    })

  }

  return (
    <div className='control-panel col' style={{minHeight: "95vh"}}>
      <div className='option-area'>
          <h3 className='font-light light-color'>
              Signal Types
          </h3>

          <div className='filters'>
              <div 
                  onClick={()=>{handleSignalToggle('NrSignal')}}
                  className={`filter-box ${filters.signalTypes.includes('NrSignal') ? 'selected': ''}`}
              >
                  <h4 className={`light-color font-bold`}>
                      5G
                  </h4>
              </div>
              <div 
                  onClick={()=>{handleSignalToggle('LteSignal')}}
                  className={`filter-box ${filters.signalTypes.includes('LteSignal') ? 'selected': ''}`}
              >
                  <h4 className={`light-color font-bold`}>
                      4G
                  </h4>
              </div>
              <div 
                  onClick={()=>{handleSignalToggle('WcdmaSignal')}}
                  className={`filter-box ${filters.signalTypes.includes('WcdmaSignal') ? 'selected': ''}`}
              >
                  <h4 className={`light-color font-bold`}>
                      3G
                  </h4>
              </div>
              <div 
                  onClick={()=>{handleSignalToggle('GsmSignal')}}
                  className={`filter-box ${filters.signalTypes.includes('GsmSignal') ? 'selected': ''}`}
              >
                  <h4 className={`light-color font-bold`}>
                      2G
                  </h4>
              </div>
          </div>
      </div>
      <DateModeOption 
        startDate={filters.startDate} 
        endDate={filters.endDate} 
        dateModeCheckbox={dateModeCheckbox}
        dateMode={dateMode}
        handleDateModeEnter={handleDateModeEnter}
      />
      <div className='option-area'>
        <h3 className='font-light light-color'>
            Carrier Comparison
        </h3>
        
        {runAnalysisLoading ? 
          <ClipLoader size={35} color={'#FFFFFF'} /> 
        :
          <div 
        onClick={carrierComparisonClicked}
        className={`datepicker-enter`}
      >
            <h5 className='font-bold no-highlight'>
              Run Analysis  
            </h5>
          </div>
        }

        {analysisResult.length > 0 && !runAnalysisLoading ?
        <div className='analysis-result'>
          <h3 className='color-light font-p-reg'>
            Carrier Rankings: 
          </h3>
          <ol>
            {analysisResult.sort((x,y)=> y[1] - x[1]).map(result=>{
              return (
                <li className='color-light font-p-semibold'>
                  {result[0]}  
                </li>
              )
            })}
          </ol>
        </div> : null}
      </div>
    </div>
  );
}

export default CarrierControlPanel;
