import * as React from 'react';
import ClipLoader from "react-spinners/ClipLoader";

export default function IsLoading(props) {
    const isLoading = props;

    if(isLoading){
        return (
            <div className="map-top-box flex-col justify-center align-center">
                <h6 className='font-p-reg color-light margin-bottom-1'>
                    Loading
                </h6>
                <ClipLoader size={35} color={'#FFFFFF'} />
            </div>
        );
    }

    return null;


    
}