import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Redirect } from 'react-router-dom'
import ClipLoader from 'react-spinners/ClipLoader'
import { useDispatch } from 'react-redux'
import { login } from '../../reducers/JwtSlice'
import { userLogin } from '../../reducers/UserSlice'
import { saveTokensIntoStorage } from '../../lib/jwt'

export default function Login() {
    const dispatch = useDispatch()
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const [requestLoading, setRequestLoading] = useState(false)
    const [isRedirecting, setIsRedirecting] = useState(false)
    const isClientError = error == 'client-user-error'
    if (isRedirecting) {
        return <Redirect to="/admin-panel" />
    }

    return (
        <div
            className="login-container flex col"
            style={{ paddingTop: '5rem' }}
        >
            <div className="login-panel flex col">
                <div className="logo-container flex align-center justify-center">
                    <img
                        src={'/images/lte-logo-red.png'}
                        className={'login-logo'}
                    />
                </div>
                {error.length > 0 ? (
                    <div className="login-error-container flex justify-center align-center width-100">
                        {!isClientError ? (
                            <h6 className="font-p-reg">{error}</h6>
                        ) : (
                            <h6 className="font-p-reg client-error ">
                                <p>
                                    Your account is registered as a 'Client' type account,
                                    which only gives you authority to login to
                                    the LTE Finder Mobile Application to collect
                                    signals. You can download the app by
                                    clicking on the button below.
                                </p>
                                <span className="margin-top-1 flex col justify-center align-center">
                                    <a href="https://play.google.com/store/apps/details?id=com.ltefinder505&hl=en_US&gl=US" target="_blank">
                                        <img
                                            width={150}
                                            src="/images/google-play-image.png"
                                        />
                                    </a>
                                </span>
                            </h6>
                        )}
                    </div>
                ) : null}
                <div className="form-container flex col">
                    <div className="form-control flex col">
                        <label htmlFor="username">
                            <h4>Username</h4>
                        </label>
                        <input
                            name={'username'}
                            type="text"
                            value={username}
                            onChange={(e) => {
                                setUsername(e.target.value)
                            }}
                        />
                    </div>
                    <div className="form-control flex col">
                        <label htmlFor="username">
                            <h4>Password</h4>
                        </label>
                        <input
                            name={'password'}
                            type="password"
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value)
                            }}
                        />
                    </div>
                    <button
                        className="login-button justify-center align-center"
                        onClick={() => {
                            if (!requestLoading) {
                                setRequestLoading(true)
                                axios
                                    .post('/login', { username, password })
                                    .then((res) => {
                                        const userData = res.data.userData
                                        if (userData?.userType !== 'client') {
                                            const loginResult = {
                                                refreshToken:
                                                    res.data.refreshToken,
                                                accessToken:
                                                    res.headers.authorization,
                                            }
                                            console.log('userData', userData)
                                            dispatch(login(loginResult))
                                            dispatch(userLogin({ userData }))
                                            saveTokensIntoStorage(
                                                loginResult.refreshToken,
                                                loginResult.accessToken
                                            )
                                            setRequestLoading(false)
                                            setIsRedirecting(true)
                                        } else {
                                            setRequestLoading(false)
                                            setError('client-user-error')
                                            console.log(error.request)
                                        }
                                    })
                                    .catch((error) => {
                                        setRequestLoading(false)
                                        if (error.response) {
                                            // The request was made and the server responded with a status code
                                            // that falls out of the range of 2xx
                                            setError(error.response.data?.error)
                                            setRequestLoading(false)
                                        } else if (error.request) {
                                            setRequestLoading(false)
                                            setError(
                                                "We're Sorry, there was a Server Error"
                                            )
                                            console.log(error.request)
                                        } else {
                                            // Something happened in setting up the request that triggered an Error
                                            console.log('Error', error.message)
                                        }
                                        console.log(error.config)
                                    })
                            } else {
                                //do nothing
                            }
                        }}
                    >
                        {requestLoading ? (
                            <ClipLoader size={35} color={'FFF'} />
                        ) : (
                            'Login'
                        )}
                    </button>
                </div>
            </div>
        </div>
    )
}
