import axios from "axios";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import {logout, refresh} from '../reducers/JwtSlice';
const ACCESS_TOKEN = 'lkjhhjkl';
const REFRESH_TOKEN = 'asdffdsa';
const REFRESH_DATE = 'qwerrewq';
const ACCESS_DATE = 'zxcvvcxz';

export const isRefreshValid = (refreshDate) => {
    if(!refreshDate){
        return false;
    }

    let date = Number(new Date(refreshDate));
    let today = Date.now()
    let monthFromNowDate = date + 1000 * 60 * 60 * 24 * 31;   // current date's milliseconds - 1,000 ms * 60 s * 60 mins * 24 hrs * (# of days beyond one to go back)
    let monthFromNow = Number(new Date(monthFromNowDate));
    return (monthFromNow - today) > 0;
}

export const getTokensFromStorage = () => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN);
    const refreshToken = localStorage.getItem(REFRESH_TOKEN);
    const refreshDate = Number(localStorage.getItem(REFRESH_DATE));
    const accessDate = Number(localStorage.getItem(ACCESS_DATE));

    if(accessToken && refreshToken){
        return {
            accessToken,
            refreshToken,
            refreshDate,
            accessDate
        }
    }else{
        return {
            accessToken: '',
            refreshToken: '',
            refreshDate: null,
            accessDate: null
        }
    }
}

export const destroyTokens = () =>{
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(ACCESS_DATE);
    localStorage.removeItem(REFRESH_DATE);

}

export const refreshAccessToken = (accessToken)=>{
    localStorage.setItem(ACCESS_TOKEN, accessToken);
    localStorage.setItem(ACCESS_DATE, Date.now().toString())
}

export const saveTokensIntoStorage = (refreshToken, accessToken) => {
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
    localStorage.setItem(ACCESS_TOKEN, accessToken);
    localStorage.setItem(ACCESS_DATE, Date.now().toString())
    localStorage.setItem(REFRESH_DATE, Date.now().toString())
}

export const useJwtRedirect = () => {
    const history = useHistory();
    const refreshToken = useSelector((state) => state.jwt.refreshToken);

    if(!refreshToken){
        history.push('/login');
    }
}

export const axiosWrapper = (requestConfig, dispatch, history)=>{
    return new Promise(function(resolve, reject){
        axios({
            method: requestConfig.method,
            url: requestConfig.url,
            data: requestConfig.data,
            refreshToken: requestConfig.refreshToken,
            headers: {
                'Authorization': requestConfig.accessToken,
            }
        })
            .then(res =>{
                resolve(res);
            })
            .catch((error)=>{
                if (error.response?.status == 401) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    if(error.response.status == 401){
                        axios.post('/user/refresh', {refreshToken: requestConfig.refreshToken})
                            .then(refreshResponse =>{
                                // Dispatch Refreshed Access Token and Reattempt Request
                                const newAccessToken = refreshResponse.headers.authorization
                                let payload = {accessToken: refreshResponse.headers.authorization};
                                refreshAccessToken(newAccessToken);
                                dispatch(refresh(payload));
                                axios({
                                    method: requestConfig.method,
                                    url: requestConfig.url,
                                    data: requestConfig.data,
                                    headers: {
                                        'Authorization': newAccessToken,
                                    }
                                })
                                    .then(refreshedThen =>{
                                        resolve(refreshedThen)
                                    })
                                    .catch(finalError =>{
                                        reject(finalError);
                                    })          
                            })
                            .catch((e =>{
                                if(e.response?.status == 401 || e.response?.status == 404){
                                    dispatch(logout)
                                    destroyTokens();
                                    if(history){
                                        history.push('/')
                                    }else{
                                        window.location.href = '/'
                                    }
                                    
                                    reject(e);
                                }
                            }))
                    }
                }else {
                    reject(error);
                    
                }
                
                 
            })
    })
    
    
}