import React, {useState, useEffect} from "react";
import Navigation from '../Navigation';
import { Link } from "react-router-dom";

export default function Figure(props){

    const {label, path, width, height, border} = props;

    return (
       <div className='flex col align-center justify-center figure'>
            <img src={path} width={width} style={border ? {border: '1px solid #000'} :{}} />
            <h6 className="font-p-reg padding-top-half center-text">{label}</h6>
        </div>
    )
}

