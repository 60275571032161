import { createSlice } from '@reduxjs/toolkit'


export const userSlice = createSlice({
  name: 'User',
  initialState: {
    attributes: {
        id: -1,
        email: '',
        firstName: '',
        lastName: '',
        institutionCenter: "",
        institutionId: -1,
        institutionName: "",
        phoneNumber: "",
        userType: "",
    },
    settings : {

    }

  },
  reducers: {
    userLogin: (state, action) => {
        state.attributes = action.payload.userData;
    },
    userLogout: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.attributes = {
        email: '',
        firstName: '',
        lastName: '',
        institutionCenter: "",
        institutionId: -1,
        institutionName: "",
        phoneNumber: "",
        userType: "",  
      }

      state.settings={}
    }
  },
})

// Action creators are generated for each case reducer function
export const {userLogin, userLogout} = userSlice.actions

export default userSlice.reducer
