import * as React from 'react';

export default function Caption(props) {
    const {caption} = props;

    return (
        <div className="map-caption flex-col">
            <h6 className='font-p-reg color-light'>
                {caption}
            </h6>
        </div>
    );
    

}
