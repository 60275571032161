
import { useSelector, useDispatch } from 'react-redux'
import {axiosWrapper as wrapper} from '../lib/jwt';
import { useHistory } from "react-router-dom";

export function useRequest(){
    const jwts = useSelector((state) => state.jwt);
    const user = useSelector((state) => state.user);
    let history = useHistory();
    const dispatch = useDispatch();

    const axiosWrapper = (requestConfig, dispatchMethod = dispatch, historyMethod = history)=>{
        requestConfig.refreshToken = jwts.refreshToken;
        requestConfig.accessToken = jwts.accessToken;
        return wrapper(requestConfig, dispatchMethod, historyMethod);
    }

    return {axiosWrapper, dispatch, jwts, user, history}
}