import { createSlice } from '@reduxjs/toolkit'
import { getTokensFromStorage } from '../lib/jwt';
const tokens = getTokensFromStorage();

export const jwtSlice = createSlice({
  name: 'jwt',
  initialState: {
    accessToken: tokens.accessToken,
    refreshToken: tokens.refreshToken,
  },
  reducers: {
    login: (state, action) => {
        state.accessToken = action.payload.accessToken;
        state.refreshToken = action.payload.refreshToken; 
    },
    logout: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value = {
          accessToken: null,
          refreshToken: null
      }
    },
    refresh: (state, action) => {
        state.accessToken = action.payload.accessToken;
    }
  },
})

// Action creators are generated for each case reducer function
export const { login, logout,refresh } = jwtSlice.actions

export default jwtSlice.reducer
