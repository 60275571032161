import { Switch } from 'pretty-checkbox-react';
import React, {useEffect, useState} from 'react';
import Datepicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'
import dateFormat from "dateformat";

function DateModeOption(props) {
    const [startDateTouched, setStartDateTouched] = useState(false);
    const [endDateTouched, setEndDateTouched] = useState(false);
    const {startDate, endDate, dateMode, dateModeCheckbox, handleDateModeEnter} = props;
    const [localStartDate, setLocalStartDate] = useState(startDate);
    const [localEndDate, setLocalEndDate] = useState(endDate);

    useEffect(() => {
      
      if(!dateModeCheckbox.state){
        setLocalStartDate(startDate);
        setLocalEndDate(endDate);
        setStartDateTouched(false);
        setEndDateTouched(false);
      }

      return () => {
        
      }
    }, [dateModeCheckbox.state])

    const customDateInput = (label, date, touched)=>{
        return (
            <div className='datepicker-container'>
                <h4 className='font-reg light-color no-highlight'>
                    {label}
                </h4>
                <div className={`datepicker-field ${touched ? 'touched' : ''}`}>
                    <h5 className='font-bold light-color no-highlight'>
                        {dateFormat(date, "mm/dd/yyyy")}
                    </h5>
                </div>
            </div>
        )
      }
    
    return (
        <div className='option-area'>
          <h3 className='font-light light-color'>
            Date Range
          </h3>
          <div className='datemode-checkbox-wrapper'>
            <Switch 
              {...dateModeCheckbox} 
              shape='fill' 
              color='success'
            />
            <h6 className='font-light light-color'>            
                Use Date Ranges for Signal Selections           
            </h6>
          </div>
          {!dateMode ? 
            <h6 className='font-p-bold light-color helper-message'>
              Currently using the most recent 500 Cellular Signals
            </h6> 
            : null
          }
          {dateModeCheckbox.state ? <div className='options-row-area justify-center'>
              <div className='datepicker-wrapper'>
                  <Datepicker 
                    wrapperClassName='datepicker' 
                    customInput={customDateInput('Start', localStartDate, startDateTouched)}
                    onChange={(date)=>{
                      setStartDateTouched(true);
                      setLocalStartDate(date);
                    }}
                  />
                  <Datepicker 
                    wrapperClassName='datepicker' 
                    customInput={customDateInput('End', localEndDate, endDateTouched)}
                    onChange={(date)=>{
                      setEndDateTouched(true);
                      setLocalEndDate(date);
                    }}
                  />
              </div>
              <div 
                className='datepicker-enter'
                onClick={()=>{
                  setStartDateTouched(false);
                  setEndDateTouched(false);
                  handleDateModeEnter(localStartDate, localEndDate)
                }}
              >
                <h5 className='font-bold no-highlight'>
                    {!dateMode ? "ENTER" : "REFRESH"}
                </h5>
              </div>
              {dateMode ? <h6 className='font-p-bold light-color helper-message'>
                All Signals are within the following time range
              </h6> :null}
            </div> : null}
        </div>
    )
}

export default DateModeOption