export const titleize = (sentence) =>{
    if(!sentence.split) return sentence;
    sentence = sentence.replaceAll('_', ' ');
    let _titleizeWord = function(string) {
            return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
        },
        result = [];
    sentence.split(" ").forEach(function(w) {
        result.push(_titleizeWord(w));
    });
    return result.join(" ");
}

export const signalModelToString = (signalModel) =>{
    switch (signalModel) {
        case "LteSignal":
            return "LTE";
        case "NrSignal":
            return "NR";
        case "WcdmaSignal":
            return "WCDMA"
        case "CdmaSignal":
            return "CDMA";
        case "GsmSignal":
            return "GSM";
        default:
            break;
    }
}