import React, {useState, useEffect} from "react";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faUserCog, faWindowClose} from '@fortawesome/free-solid-svg-icons';
import { axiosWrapper, destroyTokens } from "../../lib/jwt";
import { useSelector, useDispatch } from 'react-redux'
import { userLogin, userLogout } from "../../reducers/UserSlice";
import { Link, useHistory } from "react-router-dom";

export default function Navigation({isFixed = false}){
    const [sidebarIsOn, setSidebarIsOn] = useState(false);
    const jwts = useSelector((state) => state.jwt);
    const user = useSelector((state) => state.user)
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if(user.attributes?.institutionId == -1){
            axiosWrapper({
                method: 'get',
                accessToken: jwts?.accessToken, 
                refreshToken: jwts?.refreshToken,               
                url: '/user-data'
            }, dispatch)
            .then(({data}) => {
                const {userData} = data;
                dispatch(userLogin({userData}));
            })
            .catch(e => {
                console.log('error getting user-data', e)
            })
            return () => {
                //cleanup
            }
        }
    }, [])
    return (
        <div className='navbar' style={{position: isFixed ? "fixed" : "inherit"}}>
            <Link to='/admin-panel'>
                <img src={'/images/lte-logo-light.png'}/>
            </Link>
            <h6 className="font-p-reg color-white">
                    {user.attributes.institutionName}
            </h6>
            <div className="navbar-settings flex align-center justify-center">
                <h5 className="font-p-reg color-white no-highlight">
                    {user.attributes.firstName + " "}
                    {user.attributes.lastName} 
                </h5>
                
                {user.attributes?.institutionId != -1 ? <FontAwesomeIcon color={"#FFF"} icon={faUserCog} style={{cursor: 'pointer'}} size='2x' onClick={()=>{setSidebarIsOn(true)}}/> : null}
            </div>
            <div className={`${ sidebarIsOn ? '' : 'sidebar-off'} sidebar col align-center flex`}>
                <div className="sidebar-section flex-row flex align-center justify-center">
                    
                    <h5 className="font-p-reg color-white no-highlight">
                        {user.attributes.firstName + " "}
                        {user.attributes.lastName} 
                    </h5>
                    <FontAwesomeIcon color={"#FFF"} icon={faWindowClose} style={{cursor: 'pointer', marginLeft: 'auto'}} size='2x' onClick={()=>{setSidebarIsOn(false)}}/>
                    
                </div>
                <Link to='/user-management'>
                    <div className="sidebar-section">
                        <h5 className="font-p-reg color-white no-highlight">
                            User Management
                        </h5>
                    </div>
                </Link>
                <Link to='/priority-locations'>
                    <div className="sidebar-section">
                        <h5 className="font-p-reg color-white no-highlight">
                            Priority Points
                        </h5>
                    </div>
                </Link>
                {/* <Link to='/carrier-map'>
                    <div className="sidebar-section">
                        <h5 className="font-p-reg color-white no-highlight">
                            Carrier Comparison
                        </h5>
                    </div>
                </Link> */}
                <Link to='/user-settings'>
                    <div className="sidebar-section">
                        <h5 className="font-p-reg color-white no-highlight">
                            User Settings
                        </h5>
                    </div>
                </Link>
                <a 
                    href="#"
                    style={{cursor: "pointer"}}
                    onClick={(e)=>{
                        e.preventDefault();
                        dispatch(userLogout);
                        destroyTokens();
                        history.push('/')
                        window.location.href = '/'
                    }}
                >
                    <div className="sidebar-section">
                        <h5 className="font-p-reg color-white no-highlight">
                            Logout
                        </h5>
                    </div>
                </a>
            </div>
        </div>
    )
}