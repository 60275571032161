import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from 'react-redux'
import DataTable, { createTheme } from 'react-data-table-component';
import dateFormat from "dateformat";
import { useHistory } from "react-router-dom";
import { userLogin } from "../../reducers/UserSlice";
import { useRequest } from "../../lib/hooks";
const customStyles = {
    tableWrapper: {
        style:{
            //boxShadow: '0px 2px 4px rgba(0,0,0,0.3)',
            borderRadius: 12
        }
    },
    table:{
        style: {
            textAlign: "center",
            boxShadow: '2px 2px 4px rgba(0,0,0,0.3)',
            border: '#000 solid 1px',
            borderRadius: 12,
            paddingTop: 12,
            paddingBottom: 12

        }
        
    },
    headRow:{
        style:{
            borderTopRadius: 12,
            paddingLeft: 10,
            paddingRight: 10,
            textAlign: "center"
        }
    },
    rows: {
        style: {
            paddingLeft: 10,
            paddingRight: 10,
            minHeight: '72px', // override the row height
            maxWidth: "100%",
            wordWrap: "break-word"
            
        },
    },
    headCells: {
        style: {
            fontSize: '22px',
            paddingLeft: '3px', // override the cell padding for head cells
            paddingRight: '3px',
            textAlign: "center",
            fontFamily: "ProximaNovaCondSemibold",
        },
    },
    cells: {
        style: {
            textAlign: "center",
            fontSize: '16px',
            paddingLeft: '4px', // override the cell padding for data cells
            paddingRight: '3px',
            fontFamily: "ProximaNovaCondSemibold",
            overflowWrap: "break-word"
        },
    },
    highlightOnHover: {
		default: 'hsl(0, 0%, 13%)',
		text: 'hsl(360, 83%, 62%)',
	},
};
createTheme('lte', {
    text: {
      primary: 'hsl(348, 100%, 11%)',
      secondary: '#2aa198',
    },
    background: {
      default: 'hsl(0, 0%, 81%)',
    },
    context: {
      background: '#cb4b16',
      text: '#FFFFFF',
    },
    divider: {
      default: '#073642',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
    striped: {
		default: 'hsl(0, 0%, 88%)',
		text: 'hsl(348, 100%, 11%)',
	},
  }, 'dark');
  

export default function PriorityPointsTable(props){
    const {highlightedPointId, setHighlightedPointId, priorityPoints} = props;
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const {dispatch, user, axiosWrapper, jwts} = useRequest();

    const columns = [
        {
            name: 'Name',
            center: true,
            selector: row => `${row.name}`,
            classNames: ["align-center", "flex", "center-text", "text-center"]
        },
        {
            name: "Created At",
            center: true,
            selector: row => {
                if(row.createdAt){
                    const date = new Date(row.createdAt)
                    return dateFormat(date, "mm/dd/yyyy")
                }
                return 'N/A'
            }
        },
        {
            name: "Coordinates",
            center: true,
            selector: row => `(${row.coordinates[0]},${row.coordinates[1]})`
        },
        {
            name: "",
            right: true,
            cell: (row, index, column, id)=>{
                return (
                    <div>
                        <button 
                            onClick={()=>{
                                if(highlightedPointId == row.id){
                                    setHighlightedPointId(-1)
                                }else{
                                    setHighlightedPointId(row.id)
                                }
                            }}
                            className="info-button"
                        >
                            <h6 className="font-p-bold">
                                {highlightedPointId == row.id ? 'Highlighted' :'Highlight'}
                            </h6>
                        </button>
                    </div>
                )
                
            }
        }
    ]

    return (
        <div className="users-table-container col">
            <h2>
                Priority Points
            </h2>
            <div className="table-container">
                <DataTable 
                    columns={columns} 
                    data={priorityPoints} 
                    theme="lte" 
                    customStyles={customStyles}
                    striped={true}
                    highlightOnHover={true}

                />
            </div>
        </div>
    )
}