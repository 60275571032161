export const lteData = [
    {
        name: 'bands',
        format: 'List (Integer)',
        description: 'Get the bands which the cell signal operates on.',
        reference: '3GPP TS 36.101 section 5.5',
        version: '30',
    },
    {
        name: 'bandwidth',
        format: 'Integer',
        description: 'Cell bandwidth in kHz',
        reference: '',
        version: '28',
    },
    {
        name: 'cid',
        format: 'Integer',
        description: '28-bit Cell Identity',
        reference: '',
        version: '17',
    },
    {
        name: 'earfcn',
        format: 'Integer',
        description: '18-bit Absolute RF Channel Number',
        reference: '',
        version: '17',
    },
    {
        name: 'mcc',
        format: 'String',
        description: '3-digit Mobile Country Code',
        reference: '',
        version: '28',
    },
    {
        name: 'mnc',
        format: 'String',
        description: '2 or 3-digit Mobile Network Code',
        reference: '',
        version: '28',
    },
    {
        name: 'pci',
        format: 'Integer',
        description: 'Physical Cell Id',
        reference: '',
        version: '17',
    },
    {
        name: 'tac',
        format: 'Integer',
        description: '16-bit Tracking Area Code',
        reference: '',
        version: '17',
    },
    {
        name: 'asu_level',
        format: 'Integer',
        description: 'RSRP in ASU. Asu is calculated based on 3GPP RSRP',
        reference: '3GPP 27.007 (Ver 10.3.0) Sec 8.69',
        version: '17',
    },
    {
        name: 'cqi',
        format: 'Integer',
        description: 'Channel quality indicator',
        reference: '3GPP TS 136.213 section 7.2.3',
        version: '26',
    },
    {
        name: 'dbm',
        format: 'Integer',
        description: 'Signal Strength in Decibels per Milliwatt',
        reference: '',
        version: '17',
    },
    {
        name: 'level',
        format: 'Integer',
        description: 'An abstract level value for the overall signal quality',
        reference: '',
        version: '17',
    },
    {
        name: 'rsrp',
        format: 'Integer',
        description:
            'Reference Signal Received power in dBm Range: -140 dBm to -43 dBm.',
        reference: '',
        version: '26',
    },
    {
        name: 'rsrq',
        format: 'Integer',
        description: 'Reference Signal Received quality',
        reference: '',
        version: '26',
    },
    {
        name: 'rssi',
        format: 'Integer',
        description:
            'Get Received Signal Strength Indication (RSSI) in dBm The value range is [-113, -51] inclusively',
        reference: 'TS 27.007 8.5 Signal quality +CSQ',
        version: '29',
    },
    {
        name: 'rssnr',
        format: 'Integer',
        description:
            'Get reference signal signal-to-noise ratio in dB Range: -20 dB to +30 dB.',
        reference: '3GPP 36.213 Sec 4.2.3',
        version: '26',
    },
    {
        name: 'timing_advance',
        format: 'Integer',
        description:
            'Get the timing advance value for LTE, as a value in range of [0,1282] inclusive',
        reference: '3GPP 36.213 Sec 4.2.3',
        version: '17',
    },
]

export const nrData = [
    {
        name: 'bands',
        format: 'Array (Integer)',
        description: 'Bands of the cell',
        reference: 'TS 38.101-1 table 5.2-1, TS 38.101-2 table 5.2-1',
        version: '30',
    },
    {
        name: 'nrarfcn',
        format: 'Integer',
        description: 'New Radio Absolute Radio Frequency Channel Number',
        reference: '',
        version: '29',
    },
    {
        name: 'mcc',
        format: 'String',
        description: 'Mobile Country Code',
        reference: '',
        version: '29',
    },
    {
        name: 'mnc',
        format: 'String',
        description: 'Mobile Network Code',
        reference: '',
        version: '29',
    },
    {
        name: 'pci',
        format: 'Integer',
        description: 'Physical Cell Id',
        reference: '',
        version: '29',
    },
    {
        name: 'nci',
        format: 'Integer',
        description: 'The 36-bit NR Cell Identity in range [0, 68719476735]',
        reference: '',
        version: '29',
    },
    {
        name: 'tac',
        format: 'Integer',
        description: 'Tracking Area Code',
        reference: '',
        version: '29',
    },
    {
        name: 'asu_level',
        format: 'Integer',
        description: 'The RSRP in ASU. Asu is calculated based on 3GPP RSRP',
        reference: '3GPP 27.007 (Ver 10.3.0) Sec 8.69',
        version: '29',
    },
    {
        name: 'csi_rsrp',
        format: 'Integer',
        description: 'CSI Reference Signal Received Power',
        reference: '3GPP TS 38.215',
        version: '29',
    },
    {
        name: 'csi_rsrq',
        format: 'Integer',
        description: 'CSI Reference Signal Received Quality',
        reference: '3GPP TS 38.215',
        version: '29',
    },
    {
        name: 'csi_sinr',
        format: 'Integer',
        description: 'CSI signal-to-noise and interference ratio',
        reference: '3GPP TS 38.215 Sec 5.1.*, 3GPP TS 38.133 10.1.16.1',
        version: '29',
    },
    {
        name: 'dbm',
        format: 'Integer',
        description: 'SS-RSRP as dBm value -140..-44dBm',
        reference: '',
        version: '29',
    },
    {
        name: 'level',
        format: 'Integer',
        description: 'An abstract level value for the overall signal quality',
        reference: '',
        version: '29',
    },
    {
        name: 'ss_rsrp',
        format: 'Integer',
        description: 'SS Reference Signal Received power',
        reference: '3GPP TS 38.215',
        version: '29',
    },
    {
        name: 'ss_rsrq',
        format: 'Integer',
        description: 'SS Reference Signal Received quality',
        reference: '3GPP TS 38.215; 3GPP TS 38.133',
        version: '29',
    },
    {
        name: 'ss_sinr',
        format: 'Integer',
        description: 'SS signal-to-noise and interference ratio',
        reference: '3GPP TS 38.215 Sec 5.1.*, 3GPP TS 38.133 10.1.16.1 ',
        version: '29',
    },
]

export const wcdmaData = [
    {
        name: 'level',
        format: 'Integer',
        description: 'An abstract level value for the overall signal quality.',
        reference: '',
        version: '18',
    },
    {
        name: 'asu_level',
        format: 'Integer',
        description: 'RSCP in ASU',
        reference: '3GPP 27.007 (Ver 10.3.0) Sec 8.69',
        version: '18',
    },
    {
        name: 'psc',
        format: 'Integer',
        description: '9-bit UMTS Primary Scrambling Code',
        reference: 'TS 25.331',
        version: '18',
    },
    {
        name: 'mcc',
        format: 'String',
        description: '3-digit Mobile Country Code',
        reference: '',
        version: '28',
    },
    {
        name: 'mnc',
        format: 'String',
        description: '2 or 3-digit Mobile Network Code',
        reference: '',
        version: '28',
    },
    {
        name: 'lac',
        format: 'Integer',
        description: '16-bit Location Area Code',
        reference: '',
        version: '18',
    },
    {
        name: 'cid',
        format: 'Integer',
        description: ' CID 28-bit UMTS Cell Identity',
        reference: 'TS 25.331',
        version: '18',
    },
    {
        name: 'dbm',
        format: 'Integer',
        description: 'RSCP as a dBm value -120 through -24dBm',
        reference: '',
        version: '18',
    },
]

export const gsmData = [
    {
        name: 'mnc',
        format: 'String',
        description: '2 or 3-digit Mobile Network Code',
        reference: '',
        version: '28',
    },
    {
        name: 'mcc',
        format: 'String',
        description: '3-digit Mobile Country Code',
        reference: '',
        version: '28',
    },
    {
        name: 'lac',
        format: 'Integer',
        description: '16-bit Location Area Code',
        reference: '',
        version: '17',
    },
    {
        name: 'cid',
        format: 'Integer',
        description: '16-bit GSM Cell Identity described',
        reference: 'TS 27.007',
        version: '17',
    },
    {
        name: 'asu_level',
        format: 'Integer',
        description: 'RSSI in ASU. Asu is calculated based on 3GPP RSSI',
        reference: '3GPP 27.007 (Ver 10.3.0) Sec 8.69',
        version: '17',
    },
    {
        name: 'dbm',
        format: 'Integer',
        description: 'Signal strength as dBm. RSSI of the measured cell',
        reference: '',
        version: '17',
    },
    {
        name: 'level',
        format: 'Integer',
        description: 'Abstract level value for the overall signal quality',
        reference: '',
        version: '17',
    },
]

export const signalMetadata = [
    {
        name: 'collected_at',
        format: 'Datetime',
        description: 'The date and time when the signal was collected.',
        reference: '',
        version: 'N/A',
    },
    {
        name: 'location',
        format: 'St Point (SRID 4326)',
        description:
            'The location where the cell signal was collected. Accuracy of the point depends on a broad range of factors, mainly quality of the cell phone location hardware used and the behavior of movement regarding speed and pattern of movement, or lack there of. Lower speeds is associated with higher cell phone location accuracy, however we have noticed relatively decent accuracy of up to 20 meters at highway speeds.',
        reference: '',
        version: 'N/A',
    },
    {
        name: 'updated_at',
        format: 'Datetime',
        description:
            'The date and time that the signal data record was last updated',
        reference: '',
        version: 'N/A',
    },
    {
        name: 'location_speed',
        format: 'Datetime',
        description:
            'The speed if it is available, in meters/second over ground.',
        reference: '',
        version: 'N/A',
    },
    {
        name: 'location_bearing',
        format: 'Datetime',
        description: 'The bearing, in degrees.',
        reference: '',
        version: 'N/A',
    },
    {
        name: 'location_accuracy',
        format: 'Datetime',
        description:
            'The estimated horizontal accuracy of this location, radial, in meters',
        reference: '',
        version: 'N/A',
    },
]
