import React, { useState, useEffect } from 'react'
import ReactMapGL, { Source, Layer, Popup } from 'react-map-gl'
import { getCoordinatesFromStringPoint } from '../../lib/geo'
import { circleLayer } from '../Map/heatmap-layer'
import PopupData from '../Map/PopupData';
import { useRequest } from '../../lib/hooks'

export default function ExpandedPointRow(props) {
    const { priorityPoint, isExpanded } = props
    const [hasRequested, setHasRequested] = useState(false)
    const [cellSignals, setCellSignals] = useState([])
    const { axiosWrapper, dispatch, jwts, user } = useRequest()
    const [popupData, setPopupData] = useState({
        features: [],
        lat: null,
        lng: null,
    })

    const coords = getCoordinatesFromStringPoint(priorityPoint.location)

    const priorityPointFeature = {
        type: 'Feature',
        geometry: {
            type: 'Point',
            coordinates: coords,
        },
        properties: {},
    }

    const onMapClick = ({ lngLat, features }) => {
        const popUpFeatures = features
            .filter(
                (f) =>
                    (f.layer?.id.includes('signal-circles') ||
                        f.layer?.id.includes('priority-point')) &&
                    f.properties?.id
            )
            .map((f) => {
                console.log('PASSED', f)
                return {
                    id: f.properties?.id,
                    type:
                        f.layer?.id == 'priority-point'
                            ? 'priority-point'
                            : 'cell-signal',
                    properties: f.properties,
                }
            })
        const [lng, lat] = lngLat
        setPopupData({ features: popUpFeatures, lat, lng })
    }

    const signalLayer = {
        type: 'circle',
        paint: {
            'circle-radius': 5,
            'circle-color': '#007cbf',
            'circle-blur': 0.2,
        },
    }

    const priorityPointLayer = {
        type: 'circle',
        paint: {
            'circle-radius': 10,
            'circle-color': '#F7C948',
            'circle-blur': 0.5,
        },
    }

    const [viewport, setViewport] = useState({
        width: '100%',
        height: '400px',
        latitude: coords[1],
        longitude: coords[0],
        zoom: 11,
    })

    useEffect(() => {
        if (isExpanded && !hasRequested) {
            axiosWrapper(
                {
                    method: 'get',
                    accessToken: jwts?.accessToken,
                    refreshToken: jwts?.refreshToken,
                    url: `/priority-point-signals/${priorityPoint.id}`,
                },
                dispatch
            )
                .then(({ data }) => {
                    console.log('data', data)
                    const cellSignals = data.cellSignals.map((cs) => {
                        cs.coordinates = getCoordinatesFromStringPoint(
                            cs.location
                        )
                        return cs
                    })
                    setCellSignals([...cellSignals])
                    setHasRequested(true)
                })
                .catch((err) => {
                    console.log('Error on Requesting Priority Point', err)
                })
        }

        return () => {}
    }, [isExpanded])

    function generateDataset() {
        const features = cellSignals.map((cellSignal) => {
            console.log('cell signal', cellSignal)
            return {
                type: 'feature',
                properties: {
                    userId: cellSignal.userId,
                    signalableId: cellSignal.signalableId,
                    signalableType: cellSignal.signalableType,
                    dbm: cellSignal.dbm + 150,
                    mag: cellSignal.dbm * -0.1,
                    id: cellSignal.id,
                },
                geometry: {
                    type: 'Point',
                    coordinates: cellSignal.coordinates,
                },
            }
        })

        return {
            type: 'FeatureCollection',
            features,
        }
    }

    if (isExpanded) {
        const dataset = generateDataset()

        return (
            <div
                className="user-row-bottom col expanded width-100"
                style={{ minHeight: '200px' }}
            >
                <div>
                    <h6 className="padding-bottom-2 font-p-reg">
                        Showing the most recent 100 signals collected within a
                        one mile radius of the priority point.
                    </h6>
                </div>
                <ReactMapGL
                    {...viewport}
                    mapboxApiAccessToken={
                        'pk.eyJ1IjoianNyZXNwZWN0ZXIiLCJhIjoiY2tzZ295dHl6MW1taDMwbjU3ZTdwajE4ciJ9.9Nw0HJevfhxY7kSeEEX1Aw'
                    }
                    mapStyle="mapbox://styles/deaton747/cktgfv4tc47ny17n2cmrh5j0x"
                    onViewportChange={(nextViewport) => {
                        setViewport(nextViewport)
                    }}
                    onClick={onMapClick}
                    scrollZoom={!(popupData.features?.length > 0)}
                    asyncRender={true}
                >
                    <Source type="geojson" data={dataset}>
                        <Layer {...circleLayer()} />
                    </Source>
                    <Source type="geojson" data={priorityPointFeature}>
                        <Layer {...priorityPointLayer} />
                    </Source>
                    {popupData.features.length > 0 &&
                    popupData.lat &&
                    popupData.lng ? (
                        <Popup
                            anchor="right"
                            latitude={popupData.lat}
                            longitude={popupData.lng}
                            onMapClick={() => {
                                console.log('MAP CLICK')
                            }}
                            onClose={() => {
                                setPopupData({
                                    features: [],
                                    lat: null,
                                    lng: null,
                                })
                            }}
                        >
                            <PopupData features={popupData.features} />
                        </Popup>
                    ) : null}
                </ReactMapGL>
            </div>
        )
    }

    return (
        <div
            className="user-row-bottom col expanded width-100"
            style={{ height: '0px', padding: 0 }}
        ></div>
    )
}
