import React, {useState, useEffect} from "react";
import Navigation from '../Navigation';

export default function DemoInformation(){

    return (
       <div className='admin-panel-container'>
            <Navigation />
            <div class="docs-content-wrapper flex align-center justify-center margin-top-2">
                <div class="docs-content padding-2">
                    <h2 class="font-p-reg">
                    Demo Information
                    </h2>
                    <h5 class="font-p-light margin-top-1">
                    <p class="line-height-3">This deployment of LTE Finder is a Demo Version. To learn more about how the applications works,  visit the User Documentation link. If you would like to start collecting data, download our mobile app off the Google Play Store.</p>
                    </h5>
                    <h3 class="font-p-reg margin-top-2">
                    References
                    </h3>
                    <div class="padding-left-1">
                    <a class="font-p-reg" href="/user-docs" style="padding-top: 100rem">
                        <h5 class="font-p-reg font-weight-5 padding-top-1-5 color-red">
                        User Documentation
                        </h5>
                    </a>
                    <h6 class="font-p-reg font-weight -3 padding-top-half line-height-2 font-weight-3">
                        Visit the User Documentation page to learn about how to use both our Web and Mobile Applications as well as how our signal collection system works.
                    </h6>
                    <a class="font-p-reg" href="https://play.google.com/store/apps/details?id=com.ltefinder505&hl=en_US&gl=US" style="padding-top: 100rem">
                        <h5 class="font-p-reg font-weight-5 padding-top-1-5 color-red">
                        Google Play Store
                        </h5>
                    </a>
                    <h6 class="font-p-reg font-weight -3 padding-top-half line-height-2 font-weight-3">
                        Download our app off of the Google Play Store to start collecting signals to build your own coverage map. 
                    </h6>
                    </div>
                    <h3 class="font-p-reg margin-top-2">
                    Limitations
                    </h3>
                    <div>
                    <h5 class="font-p-light margin-top-1">
                        <p class="line-height-2 font-weight-2">Because this is application is in demo mode, your organization will have 3 significant limitations:</p>
                    </h5>
                    <ol class="padding-left-1-5 padding-top-1">
                        <li>
                        <h6 class="font-p-reg font-weight-3 padding-bottom-half">
                            You will not be able to add any users to your organization in the "User Management" section of the web portal.
                        </h6>
                        </li>
                        <li>
                        <h6 class="font-p-reg font-weight-3 padding-bottom-half">
                            Your organization will be limited to collecting 3000 signals. In addition to those 3000 signals, we have provided test data for the Albuququerque area which you can load into any map-based page in our web portal.
                        </h6>
                        </li>
                        <li>
                        <h6 class="font-p-reg font-weight-3">
                            Your ability to analyze and view your cell phone data will be stopped at 11:59PM MDT on: DATE HERE
                        </h6>
                        </li>
                    </ol>
                    </div>
                </div>
            </div>
        </div>
    )
}

