import React, {useState, useEffect} from "react";
import Navigation from '../Navigation';
import Map from '../Map';
import ControlPanel from './ControlPanel';
import { useCheckboxState } from 'pretty-checkbox-react';
import {carriers} from '../../lib/carriers';
import dateFormat from "dateformat";
import { axiosWrapper } from "../../lib/jwt";
import { useSelector, useDispatch } from 'react-redux';
import UsersTable from './UsersTable';
import {getCoordinatesFromStringPoint, parseCellSignals} from '../../lib/geo';


function AdminPanel(){

    const dateModeCheckbox = useCheckboxState();
    const [dateMode, setDateMode] = useState(false);

    const [cellSignals, setIdFilteredCellSignals] = useState([]);
    const [isLoading, setIsLoading] = useState(false);    
    const [filters, setFilters] = useState({
        signalTypes: ['NrSignal', 'CdmaSignal', 'WcdmaSignal', 'GsmSignal', 'LteSignal'],
        carriers: ['at-t','verizon','t-mobile'],
        strengths: ['poor' ,'fair', 'good', 'best'],
        startDate: (new Date(Date.now() - + 7 * 24 * 60 * 60 * 1000)),
        endDate: (new Date())
    });

    const jwts = useSelector((state) => state.jwt);
    const user = useSelector((state) => state.user);
    const [viewport, setViewport] = useState({
        width: "100%",
        height: "70vh",
        latitude: user.attributes?.institutionCenter?.length > 0 ? getCoordinatesFromStringPoint(user?.attributes.institutionCenter)[1] : null, 
        longitude: user.attributes?.institutionCenter?.length > 0 ? getCoordinatesFromStringPoint(user?.attributes.institutionCenter)[0] : null,
        zoom: 10,
    });

    const dispatch = useDispatch();

    function setCellSignals(newCellSignals){
        const uniqueIds = [];
        const filteredSignals = newCellSignals.filter(signal =>{

            const isDuplicate = uniqueIds.includes(signal.id);

            if (!isDuplicate) {
                uniqueIds.push(signal.id);

                return true;
            }

            return false;
        })

        setIdFilteredCellSignals(filteredSignals);
    }

    const setIsLoadingFalse = ()=>{
        setTimeout(()=>{
            setIsLoading(false);
        }, 500)
    }

    const addSignals = (lat, lon)=>{
        let dateQuery = ''
        if(dateMode){
            try{
                const earlierDate = filters.startDate < filters.endDate ? filters.startDate : filters.endDate;
                const laterDate = filters.startDate > filters.endDate ? filters.startDate : filters.endDate;
                const earlierDateQuery = dateFormat(earlierDate, "mm-dd-yyyy");
                const laterDateQuery = dateFormat(laterDate, "mm-dd-yyyy");
                dateQuery = `&start-date=${earlierDateQuery}&end-date=${laterDateQuery}`;
            }catch(e){
                console.log('error setting up date query', e);
            }
        }
        setIsLoading(true);
        axiosWrapper({
            method: 'get',
            accessToken: jwts?.accessToken,        
            refreshToken: jwts?.refreshToken,
            url: `/cell-signals?lat=${lat}&lon=${lon}${dateQuery}`
        }, dispatch)
            .then(({data})=>{
                setCellSignals([...cellSignals, ...parseCellSignals(data.cellSignals)
                ]);
                setIsLoadingFalse()
            })
            .catch(e =>{
                setIsLoadingFalse()
                console.log('error getting cell signals', e)
            });
    }

    useEffect(()=>{
        try{
            const [lon, lat] = getCoordinatesFromStringPoint(user.attributes.institutionCenter)
            if(lat && lon){
                setViewport({
                    width: "100%",
                    height: "70vh",
                    latitude: lat, 
                    longitude: lon,
                    zoom: 10,        
                });
            }
        }catch(e){
            console.log('ERROR SETTING', e)
        }

    }, [user])

    useEffect(()=>{
        if(dateModeCheckbox.state){
            
        }else{
            if(dateMode & viewport.latitude & viewport.longitude){
                setIsLoading(true);
                axiosWrapper({
                    method: 'get',
                    accessToken: jwts?.accessToken, 
                    refreshToken: jwts?.refreshToken,               
                    url: `/cell-signals?lat=${viewport.latitude}&lon=${viewport.longitude}`
                }, dispatch)
                .then(({data}) => {
                    setCellSignals([...parseCellSignals(data.cellSignals)]);
                    setIsLoadingFalse()
                })
                .catch(e => {
                    setIsLoadingFalse();
                    console.log('error getting cell signals', e)
                })
                setDateMode(false);
            }
        }
        
        return () =>{

        }
    }, [dateModeCheckbox, jwts, user])

    const handleDateModeEnter = (startDate, endDate)=>{
        
        const newFilters = filters;
        newFilters.startDate = startDate;
        newFilters.endDate = endDate;

        const earlierDate = startDate < endDate ? startDate : endDate;
        const laterDate = startDate > endDate ? startDate : endDate;
        setFilters({...newFilters})
        setDateMode(true);
        if(viewport.latitude && viewport.longitude){
            setIsLoading(true);
            axiosWrapper({
                method: 'get',
                accessToken: jwts?.accessToken,        
                refreshToken: jwts?.refreshToken,
                url: `/cell-signals?start-date=${dateFormat(earlierDate, "mm-dd-yyyy")}&end-date=${dateFormat(laterDate, "mm-dd-yyyy")}&lat=${viewport.latitude}&lon=${viewport.longitude}`
            }, dispatch)
                .then(({data})=>{
                    setIsLoadingFalse();
                    setCellSignals([...parseCellSignals(data.cellSignals)]);
                })
                .catch(e =>{
                    setIsLoadingFalse();
                    console.log('error getting cell signals', e)
    
                });
        }
        
    }

    useEffect(() => {
        if(viewport.latitude, viewport.longitude){
            setIsLoading(true);
            axiosWrapper({
                method: 'get',
                accessToken: jwts?.accessToken,
                refreshToken: jwts?.refreshToken,    
                url: `/cell-signals?lat=${viewport.latitude}&lon=${viewport.longitude}`
            }, dispatch)
              .then(({data}) => {
                setIsLoadingFalse();
                setCellSignals([...parseCellSignals(data.cellSignals)]);
              })
              .catch(e => {
                setIsLoadingFalse();
                
                console.log('error getting cell signals', e)
              })    
        }
      return () => {
          //cleanup
      }
    }, [jwts, user])
    
    return (
       <div className='admin-panel-container'>
            <Navigation />
            <div className='map-section'>
                <div className='map'>
                    {viewport.longitude ? <Map 
                        isLoading={isLoading}
                        data={cellSignals} 
                        filters={filters}
                        setFilters={setFilters}
                        startingPoint={user?.attributes?.institutionCenter} 
                        mode='cell-signals'
                        addSignals={addSignals}
                        viewport={viewport}
                        setViewport={setViewport}
                    /> : null}
                </div>
                <ControlPanel 
                    filters={filters}
                    setFilters={setFilters}
                    dateModeCheckbox={dateModeCheckbox}
                    dateMode={dateMode}
                    handleDateModeEnter={handleDateModeEnter}
                />
            </div>
            <div className="divider"></div>
            <UsersTable />
        </div>
    )
}

export default AdminPanel;