import ReactMapGL, {Source, Layer, Popup} from 'react-map-gl';
import React, {useState, useEffect} from "react";
import {polygonLayer, polygonOutline, determinePolygon } from './util';


export default function CarrierPolygon(props){
   const {latitude, longitude, zoom} = props;
    const polygonData = determinePolygon(latitude, longitude, zoom);
    
    return (
        <Source type="geojson" id="carrier-polygon" data={polygonData}>
            <Layer {...polygonOutline} />
            <Layer {...polygonLayer} />
        </Source>
        
    )
}
