import React, {useState, useEffect} from "react";

export default function MapViewSwitcher(props){

    const {children, onClick, backgroundColor, selectedBackgroundColor, isSelected} = props;

    const setBackgroundColor = () =>{
        if(backgroundColor && selectedBackgroundColor){
            return {backgroundColor: isSelected ? selectedBackgroundColor : backgroundColor}
        }else{
            return {}
        }
    }

    return(
        <button
            onClick={onClick}
            className="map-view-switcher"
            style={setBackgroundColor()}
        >
            {children}
        </button>
    )
}