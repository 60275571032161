import React, {useState, useEffect} from "react";

export default function MapViewSwitcherGroup(props){

    const {children} = props;

    return(
        <div className="map-view-switcher-group justify-center align-center">
            {children}
        </div>
    )
}