import * as React from 'react';
import {getDistance} from 'geolib';

export default function MapSearch(props) {
    const {getSignals, viewport, measureLat, measureLng} = props;
    const {latitude, longitude} = viewport
    const distance = getDistance({latitude, longitude}, {latitude: measureLat, longitude: measureLng}, 1)
    
    if(distance > 100000){
        return (
            <div className="map-top-box flex-col">
                <h6 className='font-p-reg color-light'>
                    Search this Area?
                </h6>
                <button 
                    className='info-button'
                    onClick={()=>{
                        getSignals();
                    }}
                >
                    <h6 className='font-p-reg'>
                        Search
                    </h6>
                </button>
            </div>
        );
    }

    return null;


    
}
