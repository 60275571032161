import React, {useState, useEffect} from "react";
import MapViewSwitcher from "./MapViewSwitcher";
import MapViewSwitcherGroup from "./MapViewSwitcherGroup";

const BLUE_1 = 'hsl(195, 100%, 94%)';
const BLUE_7 = 'hsl(202, 83%, 41%)';


export default function MapViews(props){

    const {mapFilters, setMapFilters} = props;
    const {carrierMode, mapType, measure} = mapFilters;
    
    const setFilter = (key, value)=>{
        const newMapFilters = mapFilters;
        newMapFilters[key] = value;
        setMapFilters({...newMapFilters})
    }

    const setCarrierMode = (newCarrierMode)=>{
        const newFilters = mapFilters;
        newFilters.carrierMode = newCarrierMode;
        setMapFilters({...newFilters});
    }

    const DBM_SELECTED = measure == 'dbm' && mapType == 'quality' && !mapFilters.carrierMode;
    const BARS_SELECTED = measure == 'bars' && mapType == 'quality' && !mapFilters.carrierMode;
    const DENSITY_SELECTED = mapType == 'density' && !mapFilters.carrierMode;
    const QUALITY_SELECTED = mapType == 'quality' && !mapFilters.carrierMode;
    
    return(
        <div className="map-views col align-center justify-center">
            <MapViewSwitcherGroup>
                <MapViewSwitcher
                    backgroundColor={BLUE_1}
                    selectedBackgroundColor={BLUE_7}
                    isSelected={DBM_SELECTED}
                    onClick={()=>{
                        setFilter('measure', 'dbm');
                        setFilter('mapType', 'quality');
                        setCarrierMode(false);
                    }}
                >
                    <h6 className={`font-p-reg ${DBM_SELECTED ? 'color-white' : 'dark-color'}`}>
                        dBm
                    </h6>
                </MapViewSwitcher>
                <MapViewSwitcher
                    backgroundColor={BLUE_1}
                    selectedBackgroundColor={BLUE_7}
                    isSelected={BARS_SELECTED}
                    onClick={()=>{
                        setFilter('measure', 'bars');
                        setFilter('mapType', 'quality');
                        setCarrierMode(false);
                    }}
                >
                    <h6 className={`font-p-reg ${BARS_SELECTED ? 'color-white' : 'dark-color'}`}>
                        Bars
                    </h6>
                </MapViewSwitcher>
            </MapViewSwitcherGroup>
            <MapViewSwitcherGroup>
                <MapViewSwitcher
                    isSelected={DENSITY_SELECTED}
                    backgroundColor={BLUE_1}
                    selectedBackgroundColor={BLUE_7}
                    onClick={()=>{
                        setFilter('mapType', 'density');
                        setCarrierMode(false);
                    }}
                >
                    <h6 className={`font-p-reg ${DENSITY_SELECTED? 'color-white' : 'dark-color'}`}>
                        Density
                    </h6>
                </MapViewSwitcher>
                <MapViewSwitcher
                    isSelected={QUALITY_SELECTED}
                    backgroundColor={BLUE_1}
                    selectedBackgroundColor={BLUE_7}
                    onClick={()=>{
                        setFilter('mapType', 'quality');
                        setCarrierMode(false);
                    }}
                >
                    <h6 className={`font-p-reg ${QUALITY_SELECTED ? 'color-white' : 'dark-color'}`}>
                        Quality
                    </h6>
                </MapViewSwitcher>
            </MapViewSwitcherGroup>
            <button 
                className={`${carrierMode ?'map-toggle-button selected' : 'map-toggle-button'} margin-top-1 font-p-reg ${carrierMode ? 'color-white' : 'dark-color'}`}
                onClick={()=>{
                    setCarrierMode(!carrierMode)
                }} 
            >
                <h6>
                    Carrier Mode: {carrierMode ? 'On' : 'Off'}
                </h6>
            </button>
        </div>
    )
}