import { useFormik } from 'formik';
import {titleize} from '../../lib/util';
import ClipLoader from "react-spinners/ClipLoader";
import React, {useState} from "react";
import { axiosWrapper } from "../../lib/jwt";
import { useDispatch } from 'react-redux'



export default function UserSettingsForm(props){
    // Pass the useFormik() hook initial form values, a validate function that will be called when
    // form values change or fields are blurred, and a submit function that will
    // be called when the form is submitted
    const {firstName, lastName, email, institutionName, userType, phoneNumber} = props.user;
    const [message, setMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const {accessToken, refreshToken} = props;

    

    const submitForm = (values)=>{
        setIsLoading(true);
        axiosWrapper({
            method: 'patch',
            accessToken: accessToken, 
            refreshToken: refreshToken,               
            url: '/user',
            data:{
                ...values
            }
        }, dispatch)
        .then(({data}) => {
            setTimeout(()=>{
                setIsLoading(false);
                setIsError(false);
                setMessage('Your settings have updated successfully.')
                dispatch(userLogin({userData: data.user}))
            }, 1500)
            
        })
        .catch(error => {
            setTimeout(()=>{
                setIsLoading(false);
                if(error.response){
                    setIsError(true);
                    setMessage('Error: ' + error.response.status)
                }else if (error.request){
                    setIsError(true);
                    setMessage('Your settings update request has been sent but no response was received from the future.')
                }else{
                    setIsError(true);
                    setMessage('Error: ' + error.message);
                }
                console.log('error editing user', error)
            }, 1500)
            
        })
    }

    const validate = values => {
        const errors = {};
        console.log('validating...')
        if (!values.firstName) {
          errors.firstName = 'Required';
        }else if(values.firstName.length < 2){
            errors.firstName = 'Must be at least 2 characters long';
        }else if (values.firstName.length > 15) {
          errors.firstName = 'Must be 15 characters or less';
        }
      
        if (!values.lastName) {
          errors.lastName = 'Required';
        } else if(values.lastName.length < 2){
            errors.lastName = 'Must be at least 2 characters long';
        } else if (values.lastName.length > 20) {
          errors.lastName = 'Must be 20 characters or less';
        }
      
        if (!values.email) {
          errors.email = 'Required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
          errors.email = 'Invalid email address';
        }

        if(!values.phoneNumber){
            errors.phoneNumber = 'Required'
        }else if ( ![10,11].includes(values.phoneNumber.match(/\d/g).length)){
            errors.phoneNumber = "Invalid Phone Number. Must include Area Code"
        }
      
        return errors;
      };
     
    
    const formik = useFormik({
      initialValues: {
        firstName: firstName,
        lastName: lastName,
        email: email,
        institutionName,
        userType,
        phoneNumber
      },
      validate,
      onSubmit: values => {
        submitForm(values);
      },
    });
    
    if(isLoading){
        return (
            <div className="flex col align-center justify-center padding-2">
                <ClipLoader />
            </div>
        )
    }
    
    return (
      <form 
        onSubmit={formik.handleSubmit}
        className="flex col align-center justify-center padding-2"
      >
        {message.length > 0 ? 
        <div className={`settings-message-container ${isError ? 'error' : ''}`}>
            <h5 className={`font-light ${isError ? 'color-white' : ''}`}>
                {message}
            </h5>
        </div>
        : null}
        <div className="flex row padding-top-1 justify-space-between width-100">
            <div className="flex form-control col">
                <label htmlFor="firstName">
                    <h6 className="font-reg">First Name</h6>
                </label>
                <input
                    className="font-reg"
                    id="firstName"
                    name="firstName"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.firstName}
                    onBlur={formik.handleBlur}
                />
                {formik.errors.firstName && formik.touched.firstName ? <h6 className="small color-red font-p-reg padding-top-fourth">{formik.errors.firstName}</h6> : null}
            </div>
            
            <div className="flex form-control col">
                <label htmlFor="lastName">
                    <h6 className="font-reg">Last Name</h6>
                </label>
                <input
                    className="font-reg"
                    id="lastName"
                    name="lastName"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.lastName}
                    onBlur={formik.handleBlur}
                />
                {formik.errors.lastName && formik.touched.lastName ? <h6 className="small color-red font-p-reg padding-top-fourth">{formik.errors.lastName}</h6> : null}
            </div>
        </div>
        <div className="flex row width-100 padding-top-1">
            <div className="flex form-control padding-sides-0 col">
                <label htmlFor="email">
                    <h6 className="font-reg">Email Address</h6>
                </label>
                <input
                    className="font-reg"
                    id="email"
                    name="email"
                    type="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    onBlur={formik.handleBlur}
                />
                {formik.errors.email && formik.touched.email ? <h6 className="small color-red font-p-reg padding-top-fourth">{formik.errors.email}</h6> : null}
            </div>
        </div>
        <div className="flex row width-100 padding-top-1">
            <div className="flex form-control padding-sides-0 col">
                <label htmlFor="phoneNumber">
                    <h6 className="font-reg">Phone Number</h6>
                </label>
                
                <input
                    className="font-reg"
                    id="phoneNumber"
                    name="phoneNumber"
                    type="tel"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.phoneNumber}
                />
                {formik.errors.phoneNumber  && formik.touched.phoneNumber ? <h6 className="small color-red font-p-reg padding-top-fourth">{formik.errors.phoneNumber}</h6> : null}
            </div>
        </div>
        <div className="flex row width-100 padding-top-1">
            <div className="flex form-control padding-sides-0 col">
                <label htmlFor="institutionName">
                    <h6 className="font-reg">Institution</h6>
                </label>
                <input
                    disabled
                    className="disabled font-reg"
                    id="institutionName"
                    name="institutionName"
                    type="text"
                    value={formik.values.institutionName}
                />
            </div>
        </div>
        
        <div className="flex row width-100 padding-top-1">
            <div className="flex form-control padding-sides-0 col">
                <label htmlFor="userType">
                    <h6 className="font-reg">Role</h6>
                </label>
                <input
                    disabled
                    className="disabled font-reg"
                    id="userType"
                    name="userType"
                    type="text"
                    value={titleize(formik.values.userType)}
                />
            </div>
        </div>
        <div className="flex row padding-top-2">
            <button type="submit" className="success-button color-light">
                <h6 className="font-reg">
                    Submit
                </h6>
            </button>
        </div>
        
      </form>
    );
  };
 