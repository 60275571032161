import mapCoordinates from 'geojson-apply-right-hand-rule'
import {getCoordinatesFromStringPoint} from '../../lib/geo';
const turf = require('@turf/turf');

export const polygonLayer = { 
    'id': 'carrier-polygon-fill',
    source: 'carrier-polygon',
    'type': 'fill',
    'layout': {},
    'paint': {
    'fill-color': '#0967D2', // blue color fill
    'fill-opacity': 0.5
    }
}

export const polygonOutline = {
    'id': 'carrier-polygon-outline',
    source: 'carrier-polygon',
    'type': 'line',
    'layout': {},
    'paint': {
    'line-color': '#01337D',
    'line-width': 5
    }
}

const polygonZoomMultipliers = {
    0:  {lat: .0755, lon: .0775 },
    1:  {lat: .0755, lon: .0775 },
    2:  {lat: .0755, lon: .0775 },
    3:  {lat: .0755, lon: .0775 },
    4:  {lat: .0755, lon: .0775 },
    5:  {lat: .0655, lon: .0675 },
    6:  {lat: .0555, lon: .0575 },
    7:  {lat: .0455, lon: .0475 },
    8:  {lat: .0355, lon: .0375 },
    9:  {lat: .0255, lon: .0275 },
    10: {lat: .0155, lon: .0175 },
    11: {lat: .0155, lon: .0175 },
    12: {lat: .0075, lon: .0075 },
    13: {lat: .0065, lon: .0065 },
    14: {lat: .0055, lon: .0055 },
    15: {lat: .0025, lon: .0025 },
    16: {lat: .0015, lon: .0015 },
    17: {lat: .00075, lon: .00075 },
    18: {lat: .000125, lon: .000125 },
    19: {lat: .000125, lon: .0001255 },
    20: {lat: .000125, lon: .0001255 },
    21: {lat: .000125, lon: .0001255 },
    22: {lat: .000125, lon: .0000125},
    23: {lat: .000125, lon:.000125 },
    24: {lat: .000125, lon:.000125 },
};

export const determinePolygon = (latitude, longitude, zoom)=>{
    const flooredZoom = Math.floor(zoom);
    let coordinates = [[
        [longitude - polygonZoomMultipliers[flooredZoom].lon, latitude - polygonZoomMultipliers[flooredZoom].lat],
        [longitude + polygonZoomMultipliers[flooredZoom].lon, latitude - polygonZoomMultipliers[flooredZoom].lat],
        [longitude + polygonZoomMultipliers[flooredZoom].lon, latitude + polygonZoomMultipliers[flooredZoom].lat],
        [longitude - polygonZoomMultipliers[flooredZoom].lon, latitude + polygonZoomMultipliers[flooredZoom].lat],
        [longitude - polygonZoomMultipliers[flooredZoom].lon, latitude - polygonZoomMultipliers[flooredZoom].lat]
      ]];

    coordinates = mapCoordinates(coordinates);
    return {  
        type: "FeatureCollection",
        features: [{
           "type": "Feature",
            "properties": {
                },
            "geometry": {
                  "type": "Polygon",
                   "coordinates": coordinates.coordinates
            }
        }]

    }
}

export const getComparisonCellSignalIds = (cellSignals, cLat, cLon, zoom)=>{
    
    const polygon = determinePolygon(cLat, cLon, zoom).features[0];

    return cellSignals.filter(cellSignal => {
        let [lon, lat] = getCoordinatesFromStringPoint(cellSignal.location)
        let points = turf.points([[lon, lat]]);
        const ptsWithin = turf.pointsWithinPolygon(points, polygon);
        return ptsWithin?.features?.length > 0;
    }).map(cellSignal => cellSignal.id);
}

