import * as React from 'react';

function LegendDescriptor(props){
    const {label, color, children} = props;

    return (
        <div className='legend-descriptor flex-row align-center'>
            <div className='color-box' style={{backgroundColor: color}}>

            </div>
            <h6 className='font-p-reg color-light'>
                {label || children}
            </h6>
        </div>
    )
}


export default function Legend(props) {
    const {mapFilters} = props;

    if(mapFilters.carrierMode){
        return(
            <div className='legend flex-col'>
                <LegendDescriptor color='#2BB0ED'>
                    AT&T
                </LegendDescriptor>
                <LegendDescriptor color='#27AB83'>
                    T-Mobile    
                </LegendDescriptor>
                <LegendDescriptor color='#CF1124'>
                    Verizon
                </LegendDescriptor>
            </div>
        )     
    }

    if(mapFilters.mapType == 'density'){
        return(
            <div className='legend flex-col'>
                <LegendDescriptor color='rgba( 0,33,89,1)'>
                    Low Survey Density
                </LegendDescriptor>
                <LegendDescriptor color='hsl(215, 96%, 32%)'>
                
                </LegendDescriptor>
                <LegendDescriptor color='hsl(352, 90%, 35%)'>
                    Medium Survey Density    
                </LegendDescriptor>
                <LegendDescriptor color='hsl(360, 83%, 62%)'>
                
                </LegendDescriptor>
                <LegendDescriptor color='hsl(360, 100%, 80%)'>
                    High Survey Density
                </LegendDescriptor>
            </div>
        ) 
    }else{
        if(mapFilters.measure == 'dbm'){
            return (
                <div className="legend flex-col">
                    <LegendDescriptor color='rgba( 0,33,89,1)'>
                        -120 dBms
                    </LegendDescriptor>
                    <LegendDescriptor color='hsl(216, 98%, 25%)'>
                        -110 dBms
                    </LegendDescriptor>
                    <LegendDescriptor color='hsl(215, 96%, 32%)'>
                        -100 dBms
                    </LegendDescriptor>
                    <LegendDescriptor color='hsl(352, 90%, 35%)'>
                        -90 dBms
                    </LegendDescriptor>
                    <LegendDescriptor color='hsl(360, 83%, 62%)'>
                        -80 dBms
                    </LegendDescriptor>
                    <LegendDescriptor color='hsl(360, 100%, 80%)'>
                        -70 dBms
                    </LegendDescriptor>
                </div>
            );        
        }else{
            //bars
            return (
                <div className="legend flex-col">
                    <LegendDescriptor color='hsl(216, 98%, 25%)'>
                        0 Bars
                    </LegendDescriptor>
                    <LegendDescriptor color='hsl(215, 96%, 32%)'>
                        1 Bar
                    </LegendDescriptor>
                    <LegendDescriptor color='hsl(352, 90%, 35%)'>
                        2 Bars
                    </LegendDescriptor>
                    <LegendDescriptor color='hsl(360, 83%, 62%)'>
                        3 Bars
                    </LegendDescriptor>
                    <LegendDescriptor color='hsl(360, 100%, 80%)'>
                        4 Bars
                    </LegendDescriptor>
                </div>
            )
        }
    }

    return null;
}
