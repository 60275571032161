import ReactMapGL, { Source, Layer, Popup } from 'react-map-gl'
import React, { useState, useEffect } from 'react'
import { heatmapLayer, circleLayer } from './heatmap-layer'
import { getCoordinatesFromStringPoint } from '../../lib/geo'
import Legend from './Legend'
import Caption from './Caption'
import MapSearch from './MapSearch'
import {
    attPointLayer,
    verizonPointLayer,
    tmobilePointLayer,
} from './PointLayer'
import PopupData from './PopupData'
import { generatePriorityPoints } from './util'
import IsLoading from './IsLoading'
import MapViews from './MapViews'
import CarrierPolygon from '../CarrierMap/CarrierPolygon'
import ZoomBox from './ZoomBox'

export default function Map(props) {
    const {
        isLoading,
        centerRectangle,
        data,
        filters,
        caption,
        startingPoint,
        addSignals,
        viewport,
        setViewport,
        priorityPoints,
        highlightedPointId,
    } = props
    const startingCoords = startingPoint
        ? getCoordinatesFromStringPoint(startingPoint)
        : [-106.8451991, 35.1283328]
    const [mapFilters, setMapFilters] = useState({
        measure: 'bars',
        mapType: 'density',
        carrierMode: false,
    })
    const [measureLat, setMeasureLat] = useState(startingCoords[1])
    const [measureLng, setMeasureLng] = useState(startingCoords[0])
    const [distanceTrigger, setDistanceTrigger] = useState(false)
    const [popupData, setPopupData] = useState({
        features: [],
        lat: null,
        lng: null,
    })

    const getSignals = () => {
        setMeasureLat(viewport.latitude)
        setMeasureLng(viewport.longitude)
        addSignals(viewport.latitude, viewport.longitude)
        setDistanceTrigger(false)
    }

    const onMapClick = ({ lngLat, features }) => {
        const popUpFeatures = features
            .filter(
                (f) =>
                    (f.layer?.id.includes('signal-circles') ||
                        f.layer?.id.includes('priority-point')) &&
                    f.properties?.id
            )
            .map((f) => {
                console.log('PASSED', f)
                return {
                    id: f.properties?.id,
                    type:
                        f.layer?.id == 'priority-point'
                            ? 'priority-point'
                            : 'cell-signal',
                    properties: f.properties,
                }
            })
        const [lng, lat] = lngLat
        setPopupData({ features: popUpFeatures, lat, lng })
    }

    const changeViewport = (nextViewport) => {
        setViewport(nextViewport)
    }

    const getStrengthIndicator = (cellSignal) => {
        //if(mapFilters.measure == 'bars'){
        const level = Number(cellSignal.level)
        if (level == 0) {
            return 'poor'
        } else if (level == 1) {
            return 'poor'
        } else if (level == 2) {
            return 'fair'
        } else if (level == 3) {
            return 'good'
        } else if (level >= 4) {
            return 'best'
        } else {
            return 'poor'
        }
        // }else{
        //     //dbms
        //     if(cellSignal.dbm <= -110){
        //         return 'poor'
        //     }else if(-95 > cellSignal.dbm > -100){
        //         return 'fair'
        //     }else if(-90 > cellSignal.dbm > -95){
        //         return 'good'
        //     }else if(cellSignal.dbm > -90){
        //         return 'best'
        //     }
        // }
    }

    const filterSignals = (cellSignal) => {
        const strength = getStrengthIndicator(cellSignal)

        if (!filters.carriers.includes(cellSignal.carrier)) {
            return false
        } else if (!filters.signalTypes.includes(cellSignal.signalableType)) {
            return false
        } else if (!filters.strengths.includes(strength)) {
            return false
        }

        if (filters.dateMode) {
        }

        return true
    }

    const mapData = () => {
        const cellSignals = data.filter(filterSignals)
        const attFeatures = {
            type: 'FeatureCollection',
            features: [],
        }
        const verizonFeatures = {
            type: 'FeatureCollection',
            features: [],
        }
        const tmobileFeatures = {
            type: 'FeatureCollection',
            features: [],
        }

        const features = cellSignals.map((cellSignal) => {
            const feature = {
                type: 'feature',
                properties: {
                    // userId: cellSignal.userId,
                    // signalableId: cellSignal.signalableId,
                    // signalableType: cellSignal.signalableType,
                    dbm: cellSignal.dbm,
                    level: cellSignal.level,
                    id: cellSignal.id,
                },
                geometry: {
                    type: 'Point',
                    coordinates: getCoordinatesFromStringPoint(
                        cellSignal.location
                    ),
                },
            }

            if (cellSignal.carrier == 't-mobile') {
                tmobileFeatures.features.push(feature)
            } else if (cellSignal.carrier == 'at-t') {
                attFeatures.features.push(feature)
            } else if (cellSignal.carrier == 'verizon') {
                verizonFeatures.features.push(feature)
            }

            return feature
        })

        const mainData = {
            type: 'FeatureCollection',
            features,
        }

        return { mainData, tmobileFeatures, verizonFeatures, attFeatures }
    }

    const { mainData, tmobileFeatures, verizonFeatures, attFeatures } =
        mapData()
    const renderCarrierMaps = (
        tmobileFeatures,
        verizonFeatures,
        attFeatures
    ) => {
        return (
            <>
                <Source type="geojson" data={tmobileFeatures}>
                    <Layer {...tmobilePointLayer} />
                </Source>
                <Source type="geojson" data={verizonFeatures}>
                    <Layer {...verizonPointLayer} />
                </Source>
                <Source type="geojson" data={attFeatures}>
                    <Layer {...attPointLayer} />
                </Source>
            </>
        )
    }

    return (
        <>
            <ReactMapGL
                {...viewport}
                onViewportChange={(nextViewport) => {
                    changeViewport(nextViewport)
                }}
                mapboxApiAccessToken={
                    'pk.eyJ1IjoianNyZXNwZWN0ZXIiLCJhIjoiY2tzZ295dHl6MW1taDMwbjU3ZTdwajE4ciJ9.9Nw0HJevfhxY7kSeEEX1Aw'
                }
                mapStyle="mapbox://styles/deaton747/cktgfv4tc47ny17n2cmrh5j0x"
                onClick={onMapClick}
                scrollZoom={!(popupData.features?.length > 0)}
                asyncRender={true}
            >
                <ZoomBox viewport={viewport} setViewport={setViewport} />
                <div className="map-top-section">
                    {isLoading ? <IsLoading isLoading={isLoading} /> : null}
                    <MapSearch
                        getSignals={() => {
                            getSignals()
                        }}
                        viewport={viewport}
                        measureLat={measureLat}
                        measureLng={measureLng}
                    />
                </div>
                <MapViews
                    mapFilters={mapFilters}
                    setMapFilters={setMapFilters}
                />
                <Source type="geojson" data={mainData}>
                    {mapFilters.mapType == 'density' &&
                    !mapFilters.carrierMode ? (
                        <Layer {...heatmapLayer()} />
                    ) : null}
                    {mapFilters.mapType == 'quality' &&
                    !mapFilters.carrierMode ? (
                        <Layer {...circleLayer(mapFilters.measure)} />
                    ) : null}
                </Source>
                {mapFilters.carrierMode
                    ? renderCarrierMaps(
                          tmobileFeatures,
                          verizonFeatures,
                          attFeatures
                      )
                    : null}
                {priorityPoints
                    ? generatePriorityPoints(highlightedPointId, priorityPoints)
                    : null}

                {popupData.features.length > 0 &&
                popupData.lat &&
                popupData.lng ? (
                    <Popup
                        anchor="right"
                        latitude={popupData.lat}
                        longitude={popupData.lng}
                        onMapClick={() => {
                            console.log('MAP CLICK')
                        }}
                        onClose={() => {
                            setPopupData({ features: [], lat: null, lng: null })
                        }}
                    >
                        <PopupData features={popupData.features} />
                    </Popup>
                ) : null}
                {centerRectangle ? (
                    <CarrierPolygon
                        zoom={viewport.zoom}
                        latitude={viewport.latitude}
                        longitude={viewport.longitude}
                    />
                ) : null}
            </ReactMapGL>
            {caption?.length > 0 ? <Caption caption={caption} /> : null}
            <Legend mapFilters={mapFilters} />
        </>
    )
}
