import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSearchPlus, faSearchMinus} from '@fortawesome/free-solid-svg-icons';



export default function ZoomBox({viewport, setViewport}){
    
    return (
        <div className='zoom-box'>
            <div 
                className='zoom-button' 
                onClick={(e)=>{
                    e.stopPropagation();
                    if(viewport?.zoom < 23){
                    setViewport({...viewport, zoom: viewport.zoom + 1})
                    }
                }}
            >
                <FontAwesomeIcon 
                    color={"#FFF"} 
                    icon={faSearchPlus} 
                    style={{cursor: 'pointer'}} 
                    size='lg' 
                    
                />
            </div>
            <div 
                className='zoom-button' 
                onClick={(e)=>{
                    e.stopPropagation();
                    if(viewport?.zoom > 1){
                        setViewport({...viewport, zoom: viewport.zoom - 1})
                    }}}
                >
                <FontAwesomeIcon 
                    color={"#FFF"} 
                    icon={faSearchMinus} 
                    style={{cursor: 'pointer'}} 
                    size='lg'     
                />
            </div>
            
        </div>
    )

}