const MAX_ZOOM_LEVEL = 9;

export const heatmapLayer = ()=>{
  return {
    type: 'heatmap',
    paint: {
      // Increase the heatmap weight based on frequency and property magnitude
      //'heatmap-weight': ['interpolate', ['linear'], ['get', 'dbm'], 60, 0, 100, 1],
      // Increase the heatmap color weight weight by zoom level
      // heatmap-intensity is a multiplier on top of heatmap-weight
      //'heatmap-intensity': ['interpolate', ['linear'], ['zoom'], 0, 1, MAX_ZOOM_LEVEL, 3],
      // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
      // Begin color ramp at 0-stop with a 0-transparancy color
      // to create a blur-like effect.
      'heatmap-color': [
        'interpolate',
        ['linear'],
        ['heatmap-density'],
        0,
        'rgba( 0,33,89,0)',
        0.2,
        'hsl(216, 98%, 25%)',
        0.4,
        'hsl(215, 96%, 32%)',
        0.6,
        'hsl(352, 90%, 35%)',
        0.8,
        'hsl(360, 83%, 62%)',
        0.9,
        'hsl(360, 100%, 80%)'
      ],
      // Adjust the heatmap radius by zoom level
      'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 0, 1, 12, 15],
      // Transition from heatmap to circle layer by zoom level
      'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 0, 1, 9, .5]
    }
  }
};


export const circleLayer = (measure)=> {
  
  if(measure == 'bars'){
    return {
      id: 'main-signal-circles',
      type: 'circle',
      paint: {
          'circle-radius': [
              'interpolate',
              ['exponential', 1.5],
              ['zoom'],
              13, 5,
              14, 10,
              20, 40
            ],
            'circle-stroke-color': '#000',
            'circle-color': [
              'interpolate',
              ['linear'],
              ["get", "level"],
              0,
              'hsl(216, 98%, 25%)',
              1,
              'hsl(215, 96%, 32%)',
              2,
              'hsl(352, 90%, 35%)',
              3,
              'hsl(360, 83%, 62%)',
              4,
              'hsl(360, 100%, 80%)'
            ],
            'circle-blur': 0.75,
            'circle-opacity': 1,
      }
    }
  }else{
    return {
      id: 'main-signal-circles',
      type: 'circle',
      paint: {
          'circle-radius': [
              'interpolate',
              ['exponential', 1.5],
              ['zoom'],
              13, 5,
              14, 10,
              20, 40
            ],
            'circle-stroke-color': '#000',
            'circle-color': [
              'interpolate',
              ['linear'],
              ["get", "dbm"],
              -120,
              'rgba( 0,33,89,0)',
              -110,
              'hsl(216, 98%, 25%)',
              -100,
              'hsl(215, 96%, 32%)',
              -90,
              'hsl(352, 90%, 35%)',
              -80,
              'hsl(360, 83%, 62%)',
              -70,
              'hsl(360, 100%, 80%)'
            ],
            'circle-blur': 0.75,
            'circle-opacity': 1,
      }
    }
  }
}
