import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from 'react-redux'
import { userLogin } from "../../reducers/UserSlice";
import Navigation from '../Navigation';
import UserSettingsForm from './UserSettingsForm';
import ChangePasswordForm from './ChangePasswordForm';
import {useRequest} from '../../lib/hooks';

export default function UserSettings(){
    const {jwts, user, dispatch, axiosWrapper}= useRequest();
    
    useEffect(() => {
        if(user.attributes?.institutionId == -1){
            axiosWrapper({
                method: 'get',
                accessToken: jwts?.accessToken, 
                refreshToken: jwts?.refreshToken,               
                url: '/user-data'
            }, dispatch)
            .then(({data}) => {
                console.log('data', data)
                const {userData} = data;
                dispatch(userLogin({userData}));
            })
            .catch(e => {
                console.log('error getting user-data', e)
            })
            return () => {
                //cleanup
            }
        }
    }, [])

    return (
        <div 
            className='flex col settings-container align-center'
            style={{backgroundImage: 'url("/images/techno-fabric-background.svg")'}}
        >
            <Navigation />
            <div className="settings-content col align-center">
                <div className="width-100 align-center justify-center padding-top-2 padding-bottom-2">
                    <h1 className='font-p-reg'>User Settings</h1>
                </div>
                <div className="settings-form">
                    {user.attributes?.institutionId != -1 ? 
                        <UserSettingsForm 
                            user={user.attributes}
                            accessToken={jwts.accessToken}
                            refreshToken={jwts.refreshToken}
                            dispatch={dispatch}
                        />
                    : null}
                </div>
                <div className="width-100 align-center justify-center padding-top-2 padding-bottom-2 margin-top-2">
                    <h3 className='font-p-reg'>Change Password</h3>
                </div>

                <div className="settings-form">
                    {user.attributes?.institutionId != -1 ? 
                        <ChangePasswordForm 
                            user={user.attributes}
                            accessToken={jwts.accessToken}
                            refreshToken={jwts.refreshToken}
                            dispatch={dispatch}
                        />
                    : null}
                </div>
            </div>
        </div>
    )
}

