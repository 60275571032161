import React, { useState, useEffect } from "react";
import Navigation from '../Navigation';
import { Link } from "react-router-dom";
import Figure from './Figure';

export default function MobileDocumentation() {

    return (
        <div className='admin-panel-container'>
            <Navigation isFixed={true} />
            <div className="docs-sidebar">
                <h3 className="font-p-semibold">
                    Mobile Docs
                </h3>
                <a className="color-red padding-top-1" href='#introduction'>
                    <h5 className="font-p-semibold">
                        Introduction
                    </h5>
                </a>
                <a className="color-red padding-top-half" href='#getting-started'>
                    <h5 className="font-p-semibold">
                        Getting Started
                    </h5>
                </a>
                <a className="color-red padding-top-half" href='#navigation'>
                    <h5 className="font-p-semibold">
                        Navigation
                    </h5>
                </a>
                <a className="color-red padding-top-half" href='#detailed-mode'>
                    <h5 className="font-p-semibold">
                        Detailed Mode
                    </h5>
                </a>
                <a className="color-red padding-top-half" href='#persistent-mode'>
                    <h5 className="font-p-semibold">
                        Persistent Mode
                    </h5>
                </a>
                <a className="color-red padding-top-half" href='#priority-points'>
                    <h5 className="font-p-semibold">
                        Priority Points
                    </h5>
                </a>
            </div>
            <div className="docs-wrapper">
                <div className="docs-content-wrapper flex align-center ">
                    <div className="docs-content padding-2">
                        <h2 className="font-p-reg">
                            Mobile App User Documentation
                        </h2>
                        <a className="anchor" id='introduction'>

                        </a>
                        <h3 className="font-p-reg margin-top-2">Introduction</h3>
                        <h5  className="font-p-light margin-top-1 font-weight-3">
                            <p className="line-height-3">
                                The LTE Finder Mobile App is used to track and communicate cell signal data to our web portal. While the mobile app lacks key administrative and analysis features when compared to our web portal, it is integral to the LTE Finder system because it is the main tool used to build custom coverage maps. The mobile app single handedly turns almost any phone with an Android 11+ operating system into a cell signal collection device for your organization.
                            </p>
                        </h5>
                        <a className="anchor" id='getting-started'>

                        </a>
                        <h3 className="font-p-reg margin-top-2">
                            Getting Started
                        </h3>
                        <div className="padding-left-1">
                            <ol className="directionsList font-p-reg font-weight-5 padding-top-1-5">
                                <li className="font-p-semibold directionsListItem">
                                    Download the LTE Finder app from the google play store.
                                    <br />
                                    (Note: Android must be version 11 or higher for compatibility.)
                                </li>
                                <li className="font-p-semibold padding-top-half directionsListItem">
                                    Open the LTE Finder app, you should see a Login Screen.
                                </li>
                                <li className="font-p-semibold padding-top-half directionsListItem">At the login screen, first enter your organization code. Your Institution code should be provided by you to by an administrator in your organization. </li>
                                <li className="font-p-semibold padding-top-half directionsListItem">
                                    Next, enter your Username and Password.
                                </li>
                                <li className="font-p-semibold padding-top-half directionsListItem">
                                    Finally, press the Login button.
                                </li>
                            </ol>
                            <div className="flex w-100 align-center justify-center padding-top-2">
                                <Figure
                                    label={'Login Screen. Follow steps 3 through step 5 on this screen.'}
                                    path={'/images/loginscreen.jpg'}
                                    width={300}
                                />
                            </div>
                            <ol className="font-p-reg font-weight-5 padding-top-1-5 directionsList" start={6}>
                                <li className="font-p-semibold directionsListItem">
                                    Once you are successfully logged in, you will be brought to the Detailed Signal Mode Screen.
                                </li>
                                <li className="font-p-semibold padding-top-half directionsListItem">
                                    Press the "Analyze Network" Button. You will then be prompted for permissions LTE Finder needs to run the app properly.
                                </li>
                                <li className="font-p-semibold padding-top-half directionsListItem">
                                    First, LTE Finder will ask you to allow the app to access your device's location. Select the "While Using the app" option. Your location data is explicitly used only for tracking and analyzing cellular signal quality, and nothing else.
                                </li>
                                <div className="flex w-100 align-center justify-center padding-top-2 directionsListItem">
                                    <Figure
                                        label={'Location Permission Screen. Select "While using the app".'}
                                        path={'/images/permissions1.jpg'}
                                        width={300}
                                    />
                                </div>
                                <li className="font-p-semibold padding-top-half directionsListItem">
                                    Next, LTE Finder will ask you for permission to make and manage phone calls. Select "Allow". LTE Finder won't make, manage, nor record on your behalf, we will only collect raw cell coverage data for mapping and analysis purposes.
                                </li>
                                <div className="flex w-100 align-center justify-center padding-top-2 directionsListItem">
                                    <Figure
                                        label={'Cellular Permission Screen. Select "Allow"'}
                                        path={'/images/permissions2.jpg'}
                                        width={300}
                                    />
                                </div>
                                <li className="font-p-semibold padding-top-half">
                                    Finally, press the login button.
                                </li>
                                <br />

                            </ol>

                        </div>
                        <a className="anchor" id="navigation">

                        </a>
                        <h3 className="font-p-reg margin-top-2">
                            Menu & Navigation
                        </h3>
                        <h5 className="font-p-light margin-top-1 font-weight-3">
                            <p className="line-height-3">
                                Located in the top right corner, the triple-bar icon will open the main navigation menu. When the navigation menu is open, this icon transforms into an X. Press the X to close the menu and return to the previous screen you were on. From this menu, you can go to several areas:
                            </p>
                        </h5>
                        <div className="padding-left-1">
                            <ol className="font-p-reg font-weight-5 padding-top-1-5 directionsList">
                                <div className="flex w-100 align-center justify-center padding-top-2">
                                    <Figure
                                        label={'Navigation Menu'}
                                        path={'/images/user-docs/navigation-menu.png'}
                                        width={300}
                                    />
                                </div>
                                <li className="font-p-reg directionsListItem">
                                    <b className="font-p-bold">Detailed Mode</b> If the user selects 'Detailed Mode', they will be taken to the Detailed Mode page, where they will can take one-off cell signal data reads to find out more in-depth information about their current cellular signals.
                                </li>
                                <li className="font-p-reg padding-top-half directionsListItem">
                                    <b className="font-p-bold">Priority Locations</b>
                                    If the user selects 'Priority Locations', they will be taken to the Priority Locations screen, where they can view, edit, and delete their Priority Locations.
                                </li>
                                <li className="font-p-reg padding-top-half directionsListItem">
                                    <b className="font-p-bold">Persistent Collection Mode</b> When the user selects 'Persistent Collection Mode', they will be taken to the Persistent Collection Mode screen, which allows them to survey for signals while traveling.
                                </li>
                                <li className="font-p-reg padding-top-half directionsListItem">
                                    <b className="font-p-bold">Previous Data</b> This will take the user to a page where they can see geographical locations of previous signal data they have sent.
                                </li>
                                <li className="font-p-reg padding-top-half directionsListItem">
                                    <b className="font-p-bold">Settings</b> This will take users to where they can see information about their LTEFinder Account. They can also change their password if they want to do so from here.
                                </li>
                                <li className="font-p-reg padding-top-half directionsListItem">
                                    <b className="font-p-bold">Logout</b> If you want to logout, click 'logout' once, which will prompt you to confirm if you want to logout, to protect against accidental presses. To finally logout, press the logout menu item once again.
                                </li>
                            </ol>
                        </div>
                        <a className="anchor" id='detailed-mode'>

                        </a>
                        <h3 className="font-p-reg margin-top-2" >
                            Detailed Mode
                        </h3>
                        <h5 className="font-p-light margin-top-1 font-weight-3">
                            <p className="line-height-3">
                                Detailed Mode gives the user the ability to send a one off signal and location read which prints results right to the user's screen. When the user is looking at Detailed Signal Mode, they will see all information about the Cell Signals that their phone picked up in the general vicinity. Each cell signal read will also send cellular signal and location data back to the web portal system as if the user was performing a Persistent Mode Signal Read. To perform a Detailed Mode Signal read, follow the following steps.
                            </p>
                        </h5>
                        <div className="padding-left-1">
                            <ol className="font-p-reg font-weight-5 padding-top-1-5 directionsList">
                                <li className="font-p-semibold directionsListItem">
                                    Read the information listed in the center of the screen. It will ask you to turn off Wifi capabilities on your phone before you press the 'Analyze Network Button' to actually perform the Detailed Mode signal read.
                                </li>
                                <div className="flex w-100 align-center justify-center padding-top-2">
                                    <Figure
                                        label={'Detailed Signal Mode Screen'}
                                        path={'/images/user-docs/detailed-signal-mode.png'}
                                        width={300}
                                    />
                                </div>
                                <li className="font-p-semibold padding-top-2 directionsListItem">
                                    When you are ready to see detailed information about all the cell signals in your general vicinity, press the 'Analyze Network Button'. You will then be taken to another page and where you will see a loading icon while the phone is collecting and sending cell signal and location data back to the server. This process should take around 1 to 3 seconds.
                                </li>
                                <div className="flex w-100 align-center justify-center padding-top-2">
                                    <Figure
                                        label={'Detailed Signal Mode Data'}
                                        path={'/images/user-docs/detailed-signal-mode-read.png'}
                                        width={300}
                                    />
                                </div>
                                <li className="font-p-semibold padding-top-half directionsListItem">
                                    Once your phone has collected the location and cellular signal data, it will display all cellular related data on your screen. The phone may recieve any number of signals, or it may show no signals if you don't have any current connections. Any signal data available will be shown on the screen, no cellular data that can possibly be read from your phone will be left out from a Detailed Mode signal read. For more information about the cellular data being displayed, please view our current Signal Knowledge Base.
                                </li>
                            </ol>
                        </div>
                        <a className="anchor" id='persistent-mode'>

                        </a>
                        <h3 className="font-p-reg margin-top-2" >
                            Persistent Mode
                        </h3>
                        <h5 className="font-p-light margin-top-1 font-weight-3">
                            <p className="line-height-3">
                                Persistent Mode signal collection allows users to send consistent streams of data back to their organization while traveling. Think of Persistent Mode as a way to "survey" cellular signals across large areas in the most effective manner. This is the primary way LTE Finder allows users to collect signals for large-scale geographical data analysis. To understand how to collect signals in Persistent Mode, follow the instructions below:
                            </p>
                        </h5>
                        <div className="padding-left-1">
                            <ol className="font-p-reg font-weight-5 padding-top-1-5 directionsList">
                                <div className="flex w-100 align-center justify-center padding-top-1 padding-bottom-1">
                                    <Figure
                                        label={'Persistent Mode Intro Screen'}
                                        path={'/images/user-docs/persistent_mode_intro.webp'}
                                        width={300}
                                    />
                                </div>
                                <li className="font-p-semibold directionsListItem padding-top-2">
                                    First, you will see the screen in the figure above when you navigate to the Persistent Mode Screen. This screen exists for two reasons, the first is to let the user know what data they are sending to their organization's web servers and the second is to instruct the user to disconnect from any wifi connection they may have before starting data collection. It is important for the user to disconnect from wifi before collecting signals because occasionally wifi connections can be misinterpreted as cellular signals.
                                </li>
                                <li className="font-p-semibold padding-top-2 directionsListItem padding-bottom-1">
                                    Once you have disconnected from wifi and have read information about what data you will be sending to your organization while in persistent mode, you can press the "Start Data Collection" button to begin collecting signals. Once you press this button, your phone will begin collecting signal data every 30 seconds. To get good signal reads for your organization, it is recommended to only be using Persistent Mode while directly traveling on foot, bike, motorcycle, or any other type of vehicle. This is because if you are standing still while reading cellular signals in persistent mode, it is highly likely that you will just be sending the same signals back to your organization's web server repetitively. For reading cell signals once while not traveling or moving, it is recommended to use Detailed Mode signal reads. It is also worthwhile to note that Persistent Mode (and Detailed Mode) signal reading will not work while on an airplane.
                                </li>
                                <div className="flex w-100 align-center justify-center padding-top-2 padding-bottom-2">
                                    <Figure
                                        label={'Persistent Mode While Reading Signals'}
                                        path={'/images/user-docs/persistent_mode_v2_top.png'}
                                        width={300}
                                    />
                                </div>
                            </ol>
                        </div>
                        <h5 className="font-p-light margin-top-1 font-weight-3">
                            <p className="line-height-3">
                                While you are collecting signals, the Persistent Mode display will look like that of the figure above. In the figure above, the top section is highlighted. Within this highlighted areas there are four major visual components:
                            </p>
                        </h5>
                        <div className="padding-left-1">
                            <ol className="font-p-reg font-weight-5 padding-top-1-5 directionsList">
                                <li className="font-p-semibold directionsListItem">
                                    The first section is the blue area, with the text which reads "4 Signals Collected". The number of signals collected is displayed to the user in this section. Any signal that your phone is able to detect while in your current data collection session is included in this count. Once the number of signals collected exceeds 100, the display will only read "100+".
                                </li>
                                <li className="font-p-semibold directionsListItem padding-top-2">
                                    The second section is the green "Signals Sent" section, where the text reads "1 Signals Sent" in the above figure. This section tells the user the number of signal data points which have been deemed acceptable to send back to the server. While the "Signals Collected" number tells the user how many signals have been detected, most of the time the signal data is missing certain details which are crucial in building custom cell coverage maps. Because of this, the LTE Finder Mobile App will only send signals that aren't said details. The "Signals Sent" section tells the user the amount of signals they have collected which have been sent back to their organization and will be used in the cellular coverage map. Typically, because a cell phone is only connected ton one signal at a time, user's will only collect one signal per read, although we have seen many instances of multi-signal reads.
                                </li>
                                <li className="font-p-semibold directionsListItem padding-top-2">
                                    The third section, the "Next Read" section, which is in red and has the text which reads "Next read in approximately 23 seconds" in the figure above, tells the user how many seconds they have until the next signal read.
                                </li>
                                <li className="font-p-semibold directionsListItem padding-top-2">
                                    The last visual area of the Persistent Mode Screen in the highlighted area above is the red circle animation. This animation pulsates to be larger and smaller and denotes to the user that they are currently reading signals (along with the active "Next Read" section).
                                </li>
                                <div className="flex w-100 align-center justify-center padding-top-2 padding-bottom-1">
                                    <Figure
                                        label={'Persistent Mode Display With Latest Signals Area Highlighted'}
                                        path={'/images/user-docs/persistent_mode_v2_bottom.png'}
                                        width={300}
                                    />
                                </div>
                            </ol>
                            <ol className="font-p-reg font-weight-5 padding-top-1-5 directionsList">
                                <li className="font-p-semibold directionsListItem">
                                    In the next highlighted area in this figure above, you will see the "Latest Signals" section of the Persistent Mode screen. This area is scrollabe, and you can read specific information about the signals you have collected in your current data collection section. These signal data points include both signals which aren't acceptable to be sent back to your organization's web server due to missing details as well as signals that have been deemed acceptable to send back to your organization. While each data point displays crucial details about each signal, it doesn't include every single detail available about the signal. To be able to read signal data in full detail on your phone, we recommend using Detailed Mode signal reading. If you have Admin or Super-Admin level user privileges, you can also log onto your organization's web portal.
                                </li>
                                <div className="flex w-100 align-center justify-center padding-top-2 padding-bottom-1">
                                    <Figure
                                        label={'Persistent Mode Display With Latest Signals Area Highlighted'}
                                        path={'/images/user-docs/persistent_mode_v2_button.png'}
                                        width={300}
                                    />
                                </div>
                            </ol>
                            <ol className="font-p-reg font-weight-5 padding-top-1-5 directionsList">
                                <li className="font-p-semibold directionsListItem">
                                    In the next highlighted area in this figure above, you will see the "Stop Data Collection" button highlighted. To stop collecting signals, press this button. Once pressed, you will be taken back to the Persistent Mode introduction screen. If you would like to start collecting signals again, you are free to press the button once again.
                                </li>
                            </ol>
                        </div>
                        <a className="anchor" id='priority-points'>

                        </a>
                        <h3 className="font-p-reg margin-top-2 padding-top-2">
                            Priority Points
                        </h3>
                        <h5 className="font-p-light margin-top-1 font-weight-3">
                            <p className="line-height-3">
                                When collecting signals in areas lacking landmarks or addresses, more geographical clarification is needed so that Administrators can figure out the correct location on their cell coverage map they need to analyze. To do this, LTE Finder added Priority Points. Priority Points are points that mobile users create with their phone to mark a location.
                            </p>
                        </h5>
                        <div className="flex w-100 align-center justify-center padding-top-2 padding-bottom-1">
                            <Figure
                                label={'Priority Point Screen'}
                                path={'/images/user-docs/priority-point-index.webp'}
                                width={300}
                            />
                        </div>
                        <div className="padding-left-1">
                            <ol className="font-p-reg font-weight-5 padding-top-1-5 directionsList padding-top-2">
                                <li className='font-p-semibold directionsListItem'>
                                    To create a Priority Point, first press the "Create Priority Location"
                                </li>
                                <div className="flex w-100 align-center justify-center padding-top-2 padding-bottom-1">
                                    <Figure
                                        label={'New Priority Point Screen'}
                                        path={'/images/user-docs/new-priority-point.webp'}
                                        width={300}
                                    />
                                </div>
                                <li className="font-p-semibold font-weight-5 padding-top-1-5 directionsListItem padding-top-2">After you have pressed the Create Priority Location button, you will be taken to the New Priority Point screen </li>
                                <li className="font-p-semibold font-weight-5 padding-top-1-5 directionsListItem">
                                    Enter the name of the location you would like to mark into the "Location Name" field on the screen.
                                </li>
                                <li className="font-p-semibold font-weight-5 padding-top-1-5 directionsListItem">
                                    When you are ready and in the correct location with your phone, tap the "Submit" button. Your phone will then your current location data along with the location name to your organization's web portal for administrators to analyze.
                                </li>
                                <li className="font-p-semibold font-weight-5 padding-top-1-5 directionsListItem">
                                    To look at the Priority Point on your phone, touch the Priority Point for 2 seconds, after you will be directed to a screen where you can see the Priority Point on a map.
                                </li>
                                <div className="flex w-100 align-center justify-center padding-top-2 padding-bottom-1">
                                    <Figure
                                        label={'Priority Point Details'}
                                        path={'/images/user-docs/priority-point-details.webp'}
                                        width={300}
                                    />
                                </div>
                                <li className="font-p-semibold font-weight-5 padding-top-1-5 directionsListItem">
                                    Sometimes, due to Phone capabilities, there may a difference in a few meters between your location and your Priority Point. If the Priority Point is significantly off of the location due to geolocation inaccuracy, it is recommended to delete the Priority Point and then attempting to recreate it from the same location.
                                </li>
                            </ol>
                        </div>
                        <h5 className="font-p-light margin-top-2 font-weight-3">
                            <p className="line-height-3">
                                <b>To delete a priority point, take the following steps:</b>
                            </p>
                        </h5>
                        <div className="padding-left-1">
                            <ol className="font-p-reg font-weight-5 padding-top-1-5 directionsList padding-top-2">
                                <li className='font-p-semibold directionsListItem'>
                                    To delete a Priority Point, first press the "Delete Priority Location." This will put the priority point screen in "delete mode".
                                </li>
                                <div className="flex w-100 align-center justify-center padding-top-2 padding-bottom-1">
                                    <Figure
                                        label={'Priority Point Screen Delete Mode'}
                                        path={'/images/user-docs/priority_point_delete_mode.webp'}
                                        width={300}
                                    />
                                </div>
                                <li className="font-p-semibold font-weight-5 padding-top-1-5 directionsListItem padding-top-2">When in delete mode, every priority point will have a "Trash" icon you can click to delete a priority point.</li>
                                <li className="font-p-semibold font-weight-5 padding-top-1-5 directionsListItem">
                                    Click the Garbage Icon on the priority point you want to delete, once pressed, you will see a confirmation prompt to confirm if you would like to delete the priority point.
                                </li>
                                <div className="flex w-100 align-center justify-center padding-top-2 padding-bottom-1">
                                    <Figure
                                        label={'Priority Point Screen Delete Prompt'}
                                        path={'/images/user-docs/priority_point_delete_2.webp'}
                                        width={300}
                                    />
                                </div>
                                <li className="font-p-semibold padding-top-1-5 directionsListItem">
                                    When you are sure you would like to delete the priority point, click "Delete" and the Priority Point will be deleted.
                                </li>
                                <li className="font-p-semibold padding-top-1-5 directionsListItem">
                                    To turn off Delete Mode, press the "Turn off Delete Mode" button.
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}

