import React, {useState, useEffect} from "react";
import Navigation from '../Navigation';
import { Link } from "react-router-dom";

export default function UserDocumentation(){

    return (
       <div className='admin-panel-container'>
            <Navigation />
            <div className="docs-content-wrapper flex align-center justify-center margin-top-2">
                <div className="docs-content padding-2">
                    <h2 className="font-p-reg">
                    User Documentation
                    </h2>
                    <h5 className="font-p-light margin-top-1">
                    <p className="line-height-3">Click the Links below to select User Documentation for either the Mobile App or Web Portal</p>
                    </h5>
                    <h3 className="font-p-reg margin-top-2">
                    Sections:
                    </h3>
                    <div className="padding-left-1">
                        <Link className="font-p-reg" to="/mobile-docs">
                            <h5 className="font-p-reg font-weight-5 padding-top-1-5 color-red">
                                Mobile App Documentation
                            </h5>
                        </Link>
                        <h6 className="font-p-reg font-weight -3 padding-top-half line-height-2 font-weight-3">
                            Visit the User Documentation page to learn about how to use both our Web and Mobile Applications as well as how our signal collection system works.
                        </h6>
                        <Link className="font-p-reg" to="/portal-docs">
                            <h5 className="font-p-reg font-weight-5 padding-top-1-5 color-red">
                            Web Portal Documentation
                            </h5>
                        </Link>
                        <h6 className="font-p-reg font-weight -3 padding-top-half line-height-2 font-weight-3">
                            Download our app off of the Google Play Store to start collecting signals to build your own coverage map. 
                        </h6>
                    </div>
                </div>
            </div>
        </div>
    )
}

