import { useFormik } from 'formik';
import {titleize} from '../../lib/util';
import ClipLoader from "react-spinners/ClipLoader";
import React, {useState} from "react";
import { axiosWrapper } from "../../lib/jwt";
import { useDispatch } from 'react-redux'



export default function ChangePassswordForm(props){
    const [message, setMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const {accessToken, refreshToken} = props;
    const passwordRegex = /^[a-zA-Z0-9!@_#$%^&*]{8,25}$/

    
    const validate = values => {
        const errors = {};
        console.log('validating...')
        if (!values.newPassword) {
            errors.newPassword = 'Required';
        }else if(values.newPassword.length < 8){
            errors.newPassword = 'Must be at least 8 characters long';
        }else if (values.newPassword.length > 25) {
            errors.newPassword = 'Must be 25 characters or less';
        }else if(!passwordRegex.test(values.newPassword)){
            errors.newPassword = 'Invalid Password'
        }else{
            //do nothing
        }

        if(!values.confirmNewPassword) {
            errors.confirmNewPassword = 'Required';
        }else if(values.confirmNewPassword != values.newPassword){
            errors.confirmNewPassword = "New Password Doesn't Match";
        }
        console.log('validated with errors', errors);
        return errors;
    };

    
    
    
    const formik = useFormik({
        initialValues: {
          password: '',
          newPassword: '',
          confirmNewPassword: ''
        },
        validate: validate,
        onSubmit: values => {
          submitForm(values);
        },
      });
    

    const submitForm = (values)=>{
        setIsLoading(true);
        axiosWrapper({
            method: 'put',
            accessToken: accessToken, 
            refreshToken: refreshToken,               
            url: '/user-password',
            data:{
                ...values
            }
        }, dispatch)
        .then(({data}) => {
            setTimeout(()=>{
                console.log('data', data);
                if(data.passwordChanged){
                    setIsError(false);
                    setMessage('Your password has been changed successfully.')
                    setIsLoading(false);

                }else{
                    setIsError(true);
                    setMessage(data.error);
                    setIsLoading(false);

                }
            }, 1500)
            
        })
        .catch(error => {
            setTimeout(()=>{
                setIsLoading(false);
                if(error.response){
                    setIsError(true);
                    setMessage('Error: ' + error.response.status)
                }else if (error.request){
                    setIsError(true);
                    setMessage('Your settings update request has been sent but no response was received from the server.')
                }else{
                    setIsError(true);
                    setMessage('Error: ' + error.message);
                }
                console.log('error editing user', error)
            }, 1500)
            
        })
    }


     
    
    
    if(isLoading){
        return (
            <div className="flex col align-center justify-center padding-2">
                <ClipLoader />
            </div>
        )
    }
    
    return (
      <form 
        onSubmit={formik.handleSubmit}
        className="flex col align-center justify-center padding-2 margin-top-2"
        style={{minWidth: "30rem"}}
      >
        {message.length > 0 ? 
        <div 
            className={`settings-message-container ${isError ? 'error' : ''}`}
            style={{marginBottom: '1rem'}}
        >
            <h5 className={`font-light ${isError ? 'color-white' : ''}`}>
                {message}
            </h5>
        </div>
        : null}
        <div className={`settings-message-container info`}>
            <h5 className={`font-light`}>
                Password must be between 8-25 characters long. Only Letters, Numbers, and Special Characters (!@_#$%^&*) are allowed. 
            </h5>
        </div>
        <div className="flex row padding-top-1 justify-center width-100">
            <div className="flex form-control col" style={{maxWidth: "30rem"}}>
                <label htmlFor="password">
                    <h6 className="font-reg">Current Password</h6>
                </label>
                <input
                    className="font-reg"
                    id="password"
                    name="password"
                    type="password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    onBlur={formik.handleBlur}
                />
                {formik.errors.password && formik.touched.password ? <h6 className="small color-red font-p-reg padding-top-fourth">{formik.errors.password}</h6> : null}
            </div>
        </div>
        <div className="flex row width-100 justify-center padding-top-1">
            <div className="flex form-control col" style={{maxWidth: "30rem"}}>
                <label htmlFor="newPassword">
                    <h6 className="font-reg">New Password</h6>
                </label>
                <input
                    className="font-reg"
                    id="newPassword"
                    name="newPassword"
                    type="password"
                    onChange={formik.handleChange}
                    value={formik.values.newPassword}
                    onBlur={formik.handleBlur}
                />
                {formik.errors.newPassword && formik.touched.newPassword ? <h6 className="small color-red font-p-reg padding-top-fourth">{formik.errors.newPassword}</h6> : null}
                {formik.touched.confirmNewPassword && formik.touched.newPassword && formik.values.confirmNewPassword != formik.values.newPassword ? <h6 className="small color-red font-p-reg padding-top-fourth">New Password Doesn't Match</h6> : null}

            </div>
        </div>
        <div className="flex row width-100 padding-top-1 justify-center">
            <div className="flex form-control col" style={{maxWidth: "30rem"}}>
                <label htmlFor="confirmNewPassword">
                    <h6 className="font-reg">Confirm New Password</h6>
                </label>
                <input
                    className="font-reg"
                    id="confirmNewPassword"
                    name="confirmNewPassword"
                    type="password"
                    onChange={formik.handleChange}
                    value={formik.values.confirmNewPassword}
                    onBlur={formik.handleBlur}
                />
                {formik.errors.confirmNewPassword && formik.touched.confirmNewPassword ? <h6 className="small color-red font-p-reg padding-top-fourth">{formik.errors.confirmNewPassword}</h6> : null}
            </div>
        </div>
        <div className="flex row padding-top-2">
            <button 
                type="submit" 
                className="cancel-button color-light" 
                style={{marginRight: '1rem'}}
                onClick={()=>{
                    formik.resetForm();
                }}
            >
                <h6 className="font-reg">
                    Cancel
                </h6>
            </button>
            <button 
                type="submit" 
                className="success-button color-light"
            >
                <h6 className="font-reg">
                    Submit
                </h6>
            </button>
        </div>
      </form>
    );
  };
 