import React, {useState, useEffect} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faEdit, faInfo, faInfoCircle, faTrash, faUserCog, faWindowClose} from '@fortawesome/free-solid-svg-icons';
import { axiosWrapper } from "../../lib/jwt";
import { useSelector, useDispatch } from 'react-redux'
import { userLogin } from "../../reducers/UserSlice";
import Navigation from '../Navigation';
import { titleize } from "../../lib/util";
import UserManagementForm from './UserManagementForm';
import ClipLoader from "react-spinners/ClipLoader";
import WarningModal from "../util/WarningModal";
import {useRequest} from '../../lib/hooks';

export default function UserManagement(){
    const [users, setUsers] = useState([]);
    const [newUserIsOn, setNewUserIsOn] = useState(false);
    const {jwts, axiosWrapper, dispatch} = useRequest();
    const [creationSuccessMessageOn, setCreationSuccessMessageOn] = useState(false);
    const mainUser = useSelector((state) => state.user);
    const [warningUser, setWarningUser] = useState({});
    const [warningResultMessage, setWarningResultMessage] = useState('');
    const [warningIsLoading, setWarningIsLoading] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    
    const closeNewUserForm = (isSuccess)=>{
        if(isSuccess){
            setCreationSuccessMessageOn(true);
        }
        setNewUserIsOn(false)
    }

    const NEW_USER = {
        assignedPhone: "",
        email: "",
        firstName: "",
        id: -1,
        lastName: "",
        personalPhoneNumber: "",
        userType: "client",
        username: ""
    }

    const updateUser = (user)=>{
        const newUsers = users.filter(u => u.id != user.id);
        newUsers.push(user);
        setUsers([...newUsers]);
    }

    const deleteUser = (userId) =>{
        const newUsers = users.filter(u => u.id != userId);
        setUsers([...newUsers]);
    }

    const resetPassword = ()=>{
        setWarningIsLoading(true);
        console.log('resetting for user id: ', warningUser.id);
        axiosWrapper({
            method: 'put',
            accessToken: jwts?.accessToken, 
            refreshToken: jwts?.refreshToken,
            data: {userId: warningUser.id},               
            url: '/reset-password'
        }, dispatch)
        .then(({data}) => {
            setTimeout(() => {
                if(data.passwordChanged){
                    setWarningResultMessage('Password Reset Was Successful');
                }else{
                    setWarningResultMessage(`The password reset didn't work due to the following error: ${data.error}`)
                }
                setWarningIsLoading(false);
                setWarningUser({});    
            }, 1500);
        })
        .catch(e => {
            setWarningIsLoading(false);
            setWarningResultMessage('')
            setWarningUser({});
            console.log('error getting user-data', e)
        })
    }

    useEffect(() => {
        axiosWrapper({
            method: 'get',
            accessToken: jwts?.accessToken, 
            refreshToken: jwts?.refreshToken,               
            url: '/users'
        }, dispatch)
        .then(({data}) => {
            console.log('USERS', data.users)
            setUsers([...data.users])
        })
        .catch(e => {
            console.log('error getting user-data', e)
        })
        return () => {
            //cleanup
        }
    }, [])

    let mainText = `By resetting ${warningUser.firstName} ${warningUser.lastName}'s password, they won't be allowed to login anymore with their previous password. An email will be sent to ${warningUser.firstName}'s email (${warningUser.email}) with their new password.`

    return (
        <div 
            className='flex flex-col settings-container align-center'
            style={{backgroundImage: 'url("/images/techno-fabric-background.svg")'}}
        >
            {modalIsOpen ? <WarningModal 
                modalIsOpen={modalIsOpen} 
                setModalIsOpen={setModalIsOpen} 
                afterOpenModal={()=>{}}
                warningUser={warningUser}
                mainText={mainText}
                warningIsLoading={warningIsLoading}
                warningResultMessage={warningResultMessage}
                modalTitle={'Password Reset'}
                closeButtonText={'Cancel'}
                proceedButtonText={'Proceed'}
                proceedFunction={resetPassword}
                setWarningResultMessage={setWarningResultMessage}
                setWarningIsLoading={setWarningIsLoading}
                setWarningUser={setWarningUser}
            />:null}
            <Navigation />
            <div className="settings-content flex col">
                <div className="width-100 align-center justify-center padding-top-2 padding-bottom-2">
                    <h1 className='font-p-reg'>User Management</h1>
                </div>
                <div className="align-start justify-start padding-2 flex col" style={{paddingBottom: 0}}>
                    <button 
                        className="success-button"
                        onClick={()=>{
                            setCreationSuccessMessageOn(false);
                            setNewUserIsOn(true)
                        }}
                    >
                        <h5 className="font-p-bold light-color">
                            Create User
                        </h5>
                    </button>
                </div>
                {creationSuccessMessageOn ? 
                    <div className='users-container width-100 col align-center justify-center padding-2'>
                        <h4 className="badge success font-p-bold light-color">
                            User Was Successfully Created
                        </h4>
                    </div> 
                : null}
                {newUserIsOn ? 
                    <div className='col users-container width-100 justify-center padding-2'>
                        <UserRow 
                            jwts={jwts}
                            user={NEW_USER}
                            editor={mainUser}
                            updateUser={updateUser}
                            deleteUser={deleteUser}
                            isNewUser={true}
                            closeNewUserForm={closeNewUserForm}
                            dispatch={dispatch}
                            setModalIsOpen={setModalIsOpen}
                            setWarningUser={setWarningUser}
                        />
                    </div> 
                : null}
                <div className='users-container width-100 justify-center col padding-2'>
                    {  users.sort((u1, u2) =>{
                            return (u1.id < u2.id) ? -1 : (u1.id > u2.id) ? 1 : 0;
                        })
                        .map(user=>{
                        return (
                            <UserRow 
                                jwts={jwts} 
                                user={user}
                                editor={mainUser}
                                updateUser={updateUser}
                                deleteUser={deleteUser}
                                isNewUser={false}
                                closeNewUserForm={closeNewUserForm}
                                dispatch={dispatch}
                                setModalIsOpen={setModalIsOpen}
                                setWarningUser={setWarningUser}
                            />
                        )
                    })}
                </div>               
            </div>
        </div>
    )
}

const DeletionRow = (props) =>{
    const {user, setDeletePromptOn, deleteMethod} = props;
    
    return (
        <div className='user-row col align-center justify-center col'>
            <div className="user-row-top row align-center justify-space-between width-100">
                <div className="user-row-description">
                    <h4 className="font-bold">
                        {user.firstName + " " + user.lastName}
                    </h4>
                    <h6 className='font-light'>
                        {titleize(user.userType)}
                    </h6>
                </div>
                <div className="col justify-space-between padding-1">
                    <h5 className="font-p-reg padding-bottom-1" style={{textAlign: 'center'}}>
                        Are you sure you want to delete this user?
                    </h5>
                    <div className='row align-center justify-center'>
                        <button 
                            className="delete-button-outline" 
                            style={{marginRight: 10}}
                            onClick={(e)=>{
                                setDeletePromptOn(false)
                            }}
                        >
                            <h5 className='font-p-reg'>
                                Cancel
                            </h5>
                        </button>
                        <button 
                            className="delete-button"
                            style={{marginLeft: 10}}
                            onClick={(e)=>{
                                setDeletePromptOn(false)
                                deleteMethod()
                            }}
                        >
                            <h5 className='font-p-reg  color-light'>
                                Delete
                            </h5>
                        </button>
                    </div>
                </div>
            </div>
        </div>

    )
}

const LoadingRow = (props)=>{
    const {user, isNewUser} = props;
    
    return (
        <div className='user-row col align-center justify-center'>
            <div className="user-row-top row align-center justify-space-between width-100">
                <div className="user-row-description">
                    <h4 className="font-bold">
                        {isNewUser ? "New User" : `${user.firstName + " " + user.lastName}`}
                    </h4>
                    <h6 className='font-light'>
                        {isNewUser ? "" : titleize(user.userType)}
                    </h6>
                </div>
                <ClipLoader />
            </div>
        </div>
    )
}

const isDeletable = (user, editorUser)=>{
    console.log('user', user);
    console.log('editor', editor);
    const editor = editorUser?.attributes;

    if(user.userType == 'super_admin'){
        //TODO Eventually must handle a "Master Super Admin" to handle super admins deleting each other
        if(editor.userType == 'super_admin' && editor.id == user.id){
            return false;
        }else if(editor.userType == 'super_admin'){
            return true;
        }else{
            return false;
        }
    }else if(user.userType == 'admin'){
        return editor.userType == 'super_admin';
    }else if(user.userType == 'client'){
        return editor.userType == 'super_admin' || editor.userType == 'admin';
    }else{
        return false;
    }
}

const isResettable = (user, editorUser)=>{
    const editor = editorUser?.attributes;

    if(user.userType == 'super_admin'){
        //TODO Eventually must handle a "Master Super Admin" to handle super admins deleting each other
        return editor.userType == 'super_admin'
    }else if(user.userType == 'admin'){
        return editor.userType == 'super_admin' || editor.id == user.id;
    }else if(user.userType == 'client'){
        return editor.userType == 'super_admin' || editor.userType == 'admin';
    }else{
        return false;
    }
}

function UserRow(props){
    const {user, editor, jwts, updateUser, isNewUser, closeNewUserForm, deleteUser, dispatch, setModalIsOpen, setWarningUser} = props;
    const [isExpanded, setIsExpanded] = useState(isNewUser)
    const [isLoading, setIsLoading] = useState(false);
    const [deletePromptOn, setDeletePromptOn] = useState(false);
    const [isEditModeOn, setIsEditModeOn] = useState(isNewUser)
    const [message, setMessage] = useState('');

    const deletable = isDeletable(user, editor);
    const resettable = isResettable(user, editor);

    const deleteMethod = ()=>{
        setDeletePromptOn(false);
        setIsLoading(true);
        console.log('deleting...')
        axiosWrapper({
            method: "delete",
            accessToken: jwts?.accessToken, 
            refreshToken: jwts?.refreshToken,               
            url: `/user?id=${user.id}`,
        }, dispatch)
        .then(({data}) => {
            console.log('delete result', data)
            setTimeout(()=>{
                console.log('basado');
                deleteUser(data.deletedUserId)
                setIsLoading(false);
            }, 1500)
        })
        .catch(e => {
            console.log('error deleting user', e)
            setIsLoading(false);
        })
    }
    
    const closeMethod = ()=> {
        if(isNewUser){
            closeNewUserForm(false)
        }else{
            setIsExpanded(false);
        }
    }

    const submitMethod = async (callBack)=>{
        setIsLoading(true);
        setMessage('');
        console.log('call back', callBack)
        const result = await callBack;
        console.log('SUBMIT RESULT!!!', result);
        setTimeout(()=>{
            if(result.user){
                if(isNewUser){
                    setMessage('User Created Successfully')
                    updateUser(result.user);
                    setIsExpanded(false);
                    closeNewUserForm(true)
                }else{
                    setMessage('User Updated Successfully')
                    updateUser(result.user);
                    setIsExpanded(false);    
                }
            }
            setIsLoading(false);
        }, 1500)
    }

    if(isLoading){
        return <LoadingRow user={user} isNewUser={isNewUser}/>
    }

    if(deletePromptOn){
        return <DeletionRow user={user} setDeletePromptOn={setDeletePromptOn} deleteMethod={deleteMethod}/>
    }

    return (
        <div className='user-row col align-center justify-center col' key={user.id}>
            <div className="user-row-top row align-center col justify-space-between width-100">
                <div className="user-row-description">
                    <h4 className="font-bold">
                        {isNewUser ? "New User" : `${user.firstName + " " + user.lastName}`}
                    </h4>
                    {message.length > 0 ? <h6 className={`badge success font-p-bold dark-color`}>
                        {message}
                    </h6> : null}
                    { !isNewUser ? 
                        <h6 className='font-light'>
                            {titleize(user.userType)}
                        </h6> 
                    : null}
                </div>
                { !isNewUser ? 
                <>
                <div className="user-row-icons">
                    <FontAwesomeIcon 
                        style={{marginRight: '1rem', cursor: 'pointer'}} 
                        color={'hsl(197, 92%, 61%)'} 
                        icon={faInfoCircle} 
                        size="2x"
                        onClick={(e)=>{
                            if(isExpanded && isEditModeOn){
                                setIsEditModeOn(false);
                            }else if(!isExpanded && !isEditModeOn){
                                setIsExpanded(true);
                            }else if(!isExpanded && isEditModeOn){
                                setIsExpanded(true);
                                setIsEditModeOn(false);
                            }else{
                                setIsExpanded(false);
                                setIsEditModeOn(false);
                            }
                            
                        }}
                    />
                    <FontAwesomeIcon 
                        style={{marginRight: '1rem', cursor: 'pointer'}} 
                        color={(resettable) ? 'hsl(42, 78%, 60%)' : 'hsl(0, 0%, 62%)'} 
                        icon={faEdit} 
                        size="2x"
                        onClick={(e)=>{
                            if(resettable){
                                if(isExpanded && !isEditModeOn){
                                    setIsEditModeOn(true);
                                }else if(!isExpanded && isEditModeOn){
                                    setIsExpanded(true);
                                }else if(!isExpanded && !isEditModeOn){
                                    setIsExpanded(true);
                                    setIsEditModeOn(true);
                                }else{
                                    setIsExpanded(false);
                                    setIsEditModeOn(true);
                                }   
                            }            
                        }}
                    />
                    <FontAwesomeIcon 
                        style={{cursor: 'pointer'}} 
                        icon={faTrash} 
                        size="2x" 
                        color={(!deletable) ? 'hsl(0, 0%, 62%)' : 'hsl(360, 83%, 62%)'}
                        
                        onClick={(e)=>{
                            if(!deletable){
                                // do nothing (as of right now)
                            }else{
                                setDeletePromptOn(true);
                            }
                        }}
                    />
                </div>
                </> :null}
            </div>
            <UserManagementForm
                isNewUser={isNewUser}
                user={user}
                editor={editor}
                isExpanded={isExpanded}
                isEditModeOn={isEditModeOn}
                closeMethod={closeMethod}
                submitMethod={submitMethod}
                jwts={jwts}
                setModalIsOpen={setModalIsOpen}
                setWarningUser={setWarningUser}
                resettable={resettable}
            />
        </div>
    )
}