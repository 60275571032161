import React, {useState, useEffect} from "react";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faUserCog, faWindowClose} from '@fortawesome/free-solid-svg-icons';
import { axiosWrapper } from "../../lib/jwt";
import { useSelector, useDispatch } from 'react-redux'
import { userLogin } from "../../reducers/UserSlice";
import Navigation from '../Navigation';

export default function MapSettings(){
    const [sidebarIsOn, setSidebarIsOn] = useState(false);
    const jwts = useSelector((state) => state.jwt);
    const user = useSelector((state) => state.user)
    const dispatch = useDispatch();

    useEffect(() => {
        if(user.attributes?.institutionId == -1){
            axiosWrapper({
                method: 'get',
                accessToken: jwts?.accessToken, 
                refreshToken: jwts?.refreshToken,               
                url: '/user-data'
            }, dispatch)
            .then(({data}) => {
                console.log('data', data)
                const {userData} = data;
                dispatch(userLogin({userData}));
            })
            .catch(e => {
                console.log('error getting user-data', e)
            })
            return () => {
                //cleanup
            }
        }
    }, [])

    return (
        <div 
            className='flex flex-col settings-container align-center'
            style={{backgroundImage: 'url("/images/techno-fabric-background.svg")'}}
        >
            <Navigation />
            <div className="settings-content">
                <div className="width-100 align-center justify-center padding-top-2 padding-bottom-2">
                    <h1 className='font-p-reg'>Map Settings</h1>
                </div>
            </div>
        </div>
    )
}